import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Sessions = () => {
  const { isLoading, data } = useApi('analytics/apps/sessions');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.sessions}
      total={data.sessions.length ? data.sessions[0].total : 0}
      filterOpts={{
        filters: [
          {
            name: 'apps',
            type: 'enum',
            enumName: 'app',
          },
          {
            name: 'oss',
            type: 'enum',
            enumName: 'os',
          },
          {
            name: 'emailSearchTextGroup',
            type: 'textGroup',
            filters: [
              {
                name: 'userEmail',
                placeholder: 'Filter by email',
              },
              {
                name: 'teacherEmail',
                placeholder: 'Filter by teacher email',
              },
              {
                name: 'username',
                placeholder: 'Filter by username',
              },
            ],
          },
          {
            name: 'sessionStartDateRange',
            type: 'dateRange',
          },
        ],
      }}
    />
  );
};

export default Sessions;

const columns = [
  {
    key: 'user_type',
    label: 'Account type',
    formatter: (userType) => userType ? enums.userType[userType].label : '',
  },
  {
    key: 'user_email',
    label: 'Account email',
    isOrderable: true,
  },
  {
    key: 'teacher_email',
    label: 'Teacher email',
    isOrderable: true,
  },
  {
    key: 'user_profile_username',
    label: 'Username',
    isOrderable: true,
  },
  {
    key: 'app',
    label: 'App',
    formatter: (app) => enums.app[app].label,
  },
  {
    key: 'os',
    label: 'Os',
    formatter: (os) => enums.os[os].label,
  },
  {
    key: 'created_at',
    label: 'Started at',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.dateTime,
  },
  {
    key: 'session_duration',
    label: 'Time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
];
