import * as dateFns from 'date-fns';
import enGB from "date-fns/locale/en-GB";
import querystring from 'querystring';
import { useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useHistory, useLocation } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale("en-GB", enGB);

const DateFilters = (props) => {
  const history = useHistory();
  const location = useLocation();

  const {
    selectMonths,
    selectWeeks,
    selectYears,
    dateFormat = 'yyyy-MM-dd',
  } = props;

  const filters = querystring.parse(location.search.slice(1))
  const [dateStart, setDateStart] = useState(dateFns.parseISO(filters.dateStart));
  const [dateEnd, setDateEnd] = useState(dateFns.parseISO(filters.dateEnd));

  return (
    <div className='date-picker-wrapper'>
      <DatePicker
        dateFormat={dateFormat}
        locale='en-GB'
        selected={dateStart}
        onChange={(d) => setDateStart(d)}
        selectsStart
        startDate={dateStart}
        endDate={dateEnd}
        filterDate={selectWeeks && ((date) => {
          const day = dateFns.getDay(date);
          return day === 1;
        })}
        showMonthYearPicker={selectMonths}
        showYearPicker={selectYears}
      />
      <DatePicker
        dateFormat={dateFormat}
        locale='en-GB'
        selected={dateEnd}
        onChange={(d) => setDateEnd(d)}
        selectsEnd
        startDate={dateStart}
        endDate={dateEnd}
        minDate={dateStart}
        filterDate={selectWeeks && ((date) => {
          const day = dateFns.getDay(date);
          return day === 0;
        })}
        showMonthYearPicker={selectMonths}
        showYearPicker={selectYears}
      />
      <button
        type='button'
        onClick={() => {
          history.push({
            pathname: location.pathname,
            search: querystring.stringify({
              ...filters,
              dateStart: dateFns.format(dateStart, 'yyyy-MM-dd'),
              dateEnd: dateFns.format(dateEnd, 'yyyy-MM-dd'),
            }),
          });
        }}
      >
        Filter
      </button>
    </div>
  );
}

export default DateFilters;
