import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Projects = () => {
  const { isLoading, data } = useApi('analytics/apps/projects');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.projects}
      total={data.projects.length ? data.projects[0].total : 0}
      filterOpts={{
        filters: [
          {
            name: 'projectTypes',
            type: 'enum',
            enumName: 'projectType',
          },
          {
            name: 'apps',
            type: 'enum',
            enumName: 'app',
          },
          {
            name: 'name',
            type: 'text',
            placeholder: 'Filter by name',
          },
        ],
      }}
    />
  );
};

export default Projects;

const columns = [
  {
    key: 'name',
    label: 'Name',
    isOrderable: true,
  },
  {
    key: 'type',
    label: 'Type',
    formatter: (type) => enums.projectType[type].label,
  },
  {
    key: 'app',
    label: 'App',
    formatter: (app) => enums.app[app].label,
  },
  {
    key: 'project_open_duration',
    label: 'Time spent',
    formatter: formatters.secondsToDuration,
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'project_open_count',
    label: 'Times opened',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'project_open_no_system_cube_count',
    label: 'Times opened without conn. main block',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'created_at',
    label: 'Created at',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.date,
  },
];
