import i18n from '../../helpers/i18n';

import SuccessIcon from '../../icons/SuccessIcon';

const Success = ({ lang }) => {
  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__title-container'>
          <SuccessIcon />
          <div className='main-banner__title'>
            {i18n.get('verifyEmail.successTitle', lang)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
