import { useHistory } from 'react-router-dom';

import enums from '../../../helpers/enums';
import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import TableInfo from '../../../components/TableInfo';

const Overview = () => {
  const history = useHistory();

  const { isLoading, data } = useApi('analytics/apps/session/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const mainInfoRows = [
    {
      label: 'User type',
      value: data.session.user_type && enums.userType[data.session.user_type].label,
    },
    {
      label: 'Email',
      value: data.session.user_email,
      onClick: () => history.push(`/analytics/apps/users/${data.session.user_id}`),
    },
    {
      label: 'Teacher email',
      value: data.session.teacher_email,
      onClick: () => history.push(`/analytics/apps/users/${data.session.teacher_id}`),
    },
    {
      label: 'Username',
      value: data.session.user_profile_username,
      onClick: () => history.push(`/analytics/apps/user-profiles/${data.session.user_profile_id}`),
    },
    {
      label: 'App',
      value: enums.app[data.session.app].label,
    },
    {
      label: 'Operating system',
      value: enums.os[data.session.os].label,
    },
    {
      label: 'Created at',
      value: formatters.dateTime(data.session.created_at),
    },
    {
      label: 'Total time',
      value: formatters.duration(data.session.session_duration),
    },
  ].filter(({ value }) => value != null);

  return (
    <div className='stats-main-container'>
      <div className='charts-container'>
        <TableInfo rows={mainInfoRows}/>
      </div>
    </div>
  );
};

export default Overview;
