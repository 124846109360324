import useApi from '../../helpers/useApi';

import NotFound from '../../NotFound';

import AlreadyBlocked from './AlreadyBlocked';
import ConfirmBlockUser from './ConfirmBlockUser';

const BlockUserConfirmation = () => {
  const { isLoading, data } = useApi('projects/share/block-sharer/validate');

  if (isLoading) {
    return null;
  }

  if (!data.error) {
    return <ConfirmBlockUser lang={data.lang} username={data.username} />
  } else if (data.error?.code === 'project-share-011') {
    return <AlreadyBlocked lang={data.lang} />
  } else {
    return <NotFound lang={data.lang} />;
  }
};

export default BlockUserConfirmation;
