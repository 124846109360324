import {
  Cell,
  Legend,
  PieChart,
  Pie,
  Tooltip,
} from 'recharts';

import enums from '../helpers/enums';
import formatters from '../helpers/formatters';

const CustomPie = (props) => {
  const {
    enumName,
    title,
    data,
    dataKey,
    nameKey,
    width = 380,
    height = 150,
    outerRadius = 70,
    legendWidth = 220,
  } = props;

  if (!data || !data.length || data[0].count === null) {
    return null;
  }

  return (
    <div className='chart-container'>
      <h3>{title}</h3>
      <PieChart
        width={width}
        height={height}
      >
        <Pie
          data={data}
          dataKey={dataKey}
          nameKey={nameKey}
          cx='50%'
          cy='50%'
          outerRadius={outerRadius}
          stroke=''
        >
          {data.map((entry, index) =>
            <Cell
              fill={getEnum(enumName, entry[nameKey]).color}
              key={index}
            />
          )}
        </Pie>
        <Legend
          width={legendWidth}
          layout='vertical'
          align='right'
          verticalAlign='top'
          margin={{ right: 0 }}
          formatter={(name, { payload: { percent } }) => {
            return `${getEnum(enumName, [name]).label} ${formatters.percentage(percent)}`;
          }}
        />
        <Tooltip formatter={(value, name) => [
          tooltipFormatters[dataKey](value),
          getEnum(enumName, [name]).label,
        ]}/>
      </PieChart>
    </div>
  );
};

export default CustomPie;

const tooltipFormatters = {
  count: (value) => value,
  duration: (value) => formatters.secondsToDuration(value),
};

const getEnum = (enumName, key) => {
  if (enums[enumName] && enums[enumName][key]) {
    return enums[enumName][key];
  } else {
    return {
      label: '<label missing>',
      color: '#000000',
    };
  }
};
