import { useHistory, useRouteMatch } from 'react-router-dom';

import enums from '../../../helpers/enums';
import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import Table from '../../../components/Table';

const Events = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const { isLoading, data } = useApi('analytics/apps/session/events');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      data={data.events}
      total={data.events.length ? data.events[0].total : 0}
      columns={columns(history, url)}
      disableRowClick
    />
  );
};

export default Events;

const columns = (history, url) => {
  return [
    {
      key: 'timestamp',
      label: 'Timestamp',
      isOrderable: true,
      defaultOrderDir: 'ASC',
      formatter: formatters.dateTime,
    },
    {
      key: 'type',
      label: 'Type',
    },
    {
      key: 'project_type',
      label: 'Project type',
      formatter: (projectType) => projectType && enums.projectType[projectType].label,
      onClick: ({ project_id }) => history.push(`/apps/projects/${project_id}`),
    },
    {
      key: 'project_name',
      label: 'Project name',
    },
  ];
};
