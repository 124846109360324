import * as dateFns from 'date-fns';
import { findKey } from 'lodash';

const formatters = {};

formatters.boolean = (value) => {
  return value ? 'True' : 'False';
};

formatters.booleanYesNo = (value) => {
  return value ? 'Yes' : 'No';
};

formatters.date = (value) => {
  if (!value) {
    return '-';
  }

  return dateFns.format(dateFns.parseISO(value), 'dd.MM.yyyy');
};

formatters.dateTime = (value) => {
  if (!value) {
    return '-';
  }

  return dateFns.format(dateFns.parseISO(value), 'dd.MM.yyyy HH:mm:SS');
};

formatters.duration = (value) => {
  if (!value) {
    return '-';
  }

  if (!findKey(value, (v) => v)) {
    return;
  }

  // TODO: this code is ugly but works
  const allUnits = ['years', 'months', 'days', 'hours', 'minutes', 'seconds'];
  let units = [];
  const primaryUnit = findKey(value, (v) => v);
  const secondaryUnit = allUnits[allUnits.indexOf(primaryUnit) + 1];

  if (!primaryUnit || primaryUnit === 'milliseconds') {
    return '-';
  }

  units.push(primaryUnit);
  if (value[secondaryUnit]) {
    units.push(secondaryUnit);
  }

  return dateFns.formatDuration(value, { format: units })
};

formatters.float = (value) => {
  if (value == null) {
    return '-';
  }

  return value.toFixed(2);
};

formatters.month = (value) => {
  if (!value) {
    return '-';
  }

  return dateFns.format(dateFns.parseISO(value), 'MMMM yyyy');
};

formatters.monthShort = (value) => {
  if (!value) {
    return '-';
  }

  return dateFns.format(dateFns.parseISO(value), 'MMM');
};

formatters.percentage = (value) => {
  const percentage = value * 100;

  if (value == null) {
    return '';
  } else if (percentage >= 1 || percentage === 0) {
    return `${Math.round(percentage)}%`;
  } else {
    return `${percentage.toFixed(2)}%`;
  }
};

formatters.secondsToDuration = (value) => {
  if (value === 0) {
    return 0;
  }

  if (!value) {
    return '-';
  }

  return formatters.duration(dateFns.intervalToDuration({ start: 0, end: value * 1000 }));
};

formatters.shortDate = (value) => {
  if (!value) {
    return '-';
  }

  return dateFns.format(dateFns.parseISO(value), 'dd.MM.');
};

formatters.week = (value) => {
  if (!value) {
    return '-';
  }

  const weekStart = dateFns.parseISO(value);
  const weekEnd = dateFns.addDays(weekStart, 6);
  return `${dateFns.format(weekStart, 'dd.MM.yyyy')} - ${dateFns.format(weekEnd, 'dd.MM.yyyy')}`;
};

formatters.weekShort = (value) => {
  if (!value) {
    return '-';
  }

  const weekStart = dateFns.parseISO(value);
  const weekEnd = dateFns.addDays(weekStart, 6);
  return `${dateFns.format(weekStart, 'dd.MM.')} - ${dateFns.format(weekEnd, 'dd.MM.')}`;
};

formatters.year = (value) => {
  if (!value) {
    return '-';
  }

  return dateFns.format(dateFns.parseISO(value), 'yyyy');
};

export default formatters;
