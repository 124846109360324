import { useHistory } from 'react-router-dom';

import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';
import { BatchHelper } from '../../components/BatchHelper';

const Events = () => {
  const history = useHistory();

  const { isLoading, data } = useApi('analytics/production/events');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <>
      <BatchHelper />
      <Table
        columns={columns(history)}
        data={data.events}
        total={data.events[0] && data.events[0].total}
        disableRowClick
        filterOpts={{
          filters: [
            {
              name: 'factories',
              type: 'enum',
              enumName: 'factory'
            },
            {
              name: 'modules',
              type: 'enum',
              enumName: 'module'
            },
            {
              name: 'testResult',
              type: 'enum',
              enumName: 'testResult'
            },
            {
              name: 'jigModuleTextFields',
              type: 'textGroup',
              filters: [
                {
                  name: 'jigId',
                  type: 'text',
                  placeholder: 'Filter by Jig UUID'
                },
                {
                  name: 'moduleId',
                  type: 'text',
                  placeholder: 'Filter by Module UUID'
                }
              ]
            }
          ]
        }}
      />
    </>
  );
};

export default Events;

const columns = (history) => {
  return [
    {
      key: 'factory_id',
      label: 'Factory',
      formatter: (factoryId) => enums.factory[factoryId].label,
      onClick: ({ factory_id }) => history.push(`/analytics/production/factories/${factory_id}`)
    },
    {
      key: 'module_num',
      label: 'Module',
      formatter: (moduleNum) => `${moduleNum} - ${enums.module[moduleNum].label}`,
      isOrderable: true,
      onClick: ({ module_num }) => history.push(`/analytics/production/physical-modules/${module_num}`)
    },
    {
      key: 'jig_uuid',
      label: 'Jig UUID',
      onClick: ({ jig_uuid }) => history.push(`/analytics/production/jigs/${jig_uuid}`)
    },
    {
      key: 'module_uuid',
      label: 'Module UUID',
      onClick: ({ module_uuid }) => history.push(`/analytics/production/modules/${module_uuid}`)
    },
    {
      key: 'data_sample',
      label: 'Data sample',
      formatter: formatters.float,
      isOrderable: true,
      defaultOrderDir: 'ASC'
    },
    {
      key: 'pass_fail',
      label: 'Pass/fail',
      formatter: formatters.boolean
    },
    {
      key: 'created_at',
      label: 'Created at',
      formatter: formatters.dateTime,
      isOrderable: true,
      defaultOrderDir: 'DESC'
    }
  ];
};
