const StepIcon = ({ stepNumber }) => {
  return (
    <div className='step-icon'>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <circle
            cx='12'
            cy='12'
            r='12'
            fill='#7E63B6'
          />
          <text
            className='step-icon__number'
            x='7'
            y='18'
          >
            {stepNumber}
          </text>
        </g>
      </svg>
    </div>
  );
};

export default StepIcon;
