import * as dateFns from 'date-fns';
import { defaultsDeep, difference } from 'lodash';
import querystring from 'querystring';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import enums from '../helpers/enums';

const DefaultFilters = ({ component, type }) => {
  const location = useLocation();
  const history = useHistory();

  const defaultFilters = defaultFiltersByType[type];

  useEffect(() => {
    if (!areFiltersValid(location, defaultFilters)) {
      const filters = querystring.parse(location.search.slice(1))
      history.replace({
        pathname: location.pathname,
        search: querystring.stringify(defaultsDeep(filters, defaultFilters)),
      });
    }
  }, [location, history, defaultFilters]);

  return areFiltersValid(location, defaultFilters) ? component : null;
};

export default DefaultFilters;

const areFiltersValid = (location, defaultFilters) => {
  const filters = querystring.parse(location.search.slice(1))
  const filterKeys = Object.keys(filters);
  const defaultFilterKeys = Object.keys(defaultFilters);
  return difference(defaultFilterKeys, filterKeys).length === 0;
};

const defaultFiltersByType = {
  events: {
    orderBy: 'timestamp',
    orderDir: 'ASC',
    limit: 100,
    offset: 0,
  },
  projects: {
    orderBy: 'project_open_duration',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    projectTypes: Object.keys(enums.projectType),
    apps: Object.keys(enums.app),
  },
  sessions: {
    orderBy: 'created_at',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    apps: Object.keys(enums.app),
    oss: Object.keys(enums.os),
  },
  timeline: {
    dateStart: '2020-12-15',
    dateEnd: dateFns.format(new Date(), 'yyyy-MM-dd'),
  },
  users: {
    orderBy: 'session_duration',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    userTypes: Object.keys(enums.userType),
  },
  userProfiles: {
    orderBy: 'created_at',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    userProfileStatuses: ['active'],
    userProfileAgeGroups: Object.keys(enums.userProfileAgeGroup),
  },
  // production analytics
  timelineDaily: {
    dateStart: dateFns.format(dateFns.subMonths(new Date(), 1), 'yyyy-MM-dd'),
    dateEnd: dateFns.format(new Date(), 'yyyy-MM-dd'),
  },
  timelineWeekly: {
    dateStart: dateFns.format(dateFns.startOfWeek(dateFns.subMonths(new Date(), 3), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
    dateEnd: dateFns.format(dateFns.endOfWeek(new Date(), { weekStartsOn: 1 }), 'yyyy-MM-dd'),
  },
  timelineMonthly: {
    dateStart: dateFns.format(dateFns.startOfMonth(dateFns.subYears(new Date(), 1)), 'yyyy-MM-dd'),
    dateEnd: dateFns.format(dateFns.startOfMonth(new Date()), 'yyyy-MM-dd'),
  },
  timelineYearly: {
    dateStart: dateFns.format(dateFns.startOfYear(dateFns.subYears(new Date(), 5)), 'yyyy-MM-dd'),
    dateEnd: dateFns.format(dateFns.startOfYear(new Date()), 'yyyy-MM-dd'),
  },
  factories: {
    orderBy: 'id',
    orderDir: 'ASC',
    limit: 50,
    offset: 0,
  },
  physicalModules: {
    orderBy: 'id',
    orderDir: 'ASC',
    limit: 50,
    offset: 0,
    factories: Object.keys(enums.factory),
  },
  jigs: {
    orderBy: 'count_tests',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    factories: Object.keys(enums.factory),
    modules: Object.keys(enums.module),
  },
  modules: {
    orderBy: 'count_tests',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    factories: Object.keys(enums.factory),
    modules: Object.keys(enums.module),
  },
  productionEvents: {
    orderBy: 'created_at',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
    factories: Object.keys(enums.factory),
    modules: Object.keys(enums.module),
    testResult: Object.keys(enums.testResult),
  },
  // marketing analytics
  campaigns: {
    orderBy: 'created_at',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
  },
  channels: {
    orderBy: 'count_clicks',
    orderDir: 'DESC',
    limit: 50,
    offset: 0,
  },
};
