import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Daily = () => {
  const { isLoading, data } = useApi('analytics/production/timeline/daily');

  return (
    <div className='stats-main-container'>
      <DateFilters />
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          {data.testsPerFactoryDaily
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per factory, total tests: ${data.testsPerFactoryDaily.total || 0}`}
                  data={data.testsPerFactoryDaily.daily}
                  enumName='factory'
                />
              </div>
            ) : null
          }
          {data.testsPassedVsFailedDaily
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Passed vs. failed tests, total tests: ${data.testsPassedVsFailedDaily.total || 0}`}
                  data={data.testsPassedVsFailedDaily.daily}
                  enumName='testResult'
                />
              </div>
            ) : null
          }
          {data.testsPerModuleDaily
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per module, total tests: ${data.testsPerModuleDaily.total || 0}`}
                  data={data.testsPerModuleDaily.daily}
                  enumName='module'
                />
              </div>
            ) : null
          }
        </>
      )}
    </div>
  )
};

export default Daily;
