import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import DefaultFilters from '../../../components/DefaultFilters';

import Daily from './Daily';
import Monthly from './Monthly';
import Weekly from './Weekly';
import Yearly from './Yearly';

const Timeline = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <div className='navigation-container'>
        <Link to={`${url}/daily`} className='navigation-item'>Daily</Link>
        <Link to={`${url}/weekly`} className='navigation-item'>Weekly</Link>
        <Link to={`${url}/monthly`} className='navigation-item'>Monthly</Link>
        <Link to={`${url}/yearly`} className='navigation-item'>Yearly</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/daily`}>
          <DefaultFilters component={<Daily />} type='timelineDaily' />
        </Route>
        <Route exact path={`${path}/weekly`}>
          <DefaultFilters component={<Weekly />} type='timelineWeekly' />
        </Route>
        <Route exact path={`${path}/monthly`}>
          <DefaultFilters component={<Monthly />} type='timelineMonthly' />
        </Route>
        <Route exact path={`${path}/yearly`}>
          <DefaultFilters component={<Yearly />} type='timelineYearly' />
        </Route>
      </Switch>
    </>
  );
};

export default Timeline;
