import useApi from '../../helpers/useApi';

import NotFound from '../../NotFound';

import Success from './Success';

const BlockUserResult = () => {
  const { isLoading, data } = useApi('projects/share/block-sharer');

  if (isLoading) {
    return null;
  }

  if (!data.error || data.error.code === 'project-share-011') {
    return <Success lang={data.lang} username={data.username} />
  } else {
    return <NotFound lang={data.lang} />;
  }
};

export default BlockUserResult;
