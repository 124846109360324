const UserIcon = () => {
  return (
    <div className='project-preview__user'>
      <svg
        width='33'
        height='32'
        viewBox='0 0 33 32'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
      <circle
        cx='16.5'
        cy='16'
        r='16'
        fill='#F26730'
      />
      <path
        d='M21.5 11C21.5 13.7614 19.2614 16 16.5 16C13.7386 16 11.5 13.7614 11.5 11C11.5 8.23858 13.7386 6 16.5 6C19.2614 6 21.5 8.23858 21.5 11Z'
        fill='white'
      />
      <path
        d='M8.5 20C8.5 18.8954 9.39543 18 10.5 18H22.5C23.6046 18 24.5 18.8954 24.5 20V21C24.5 21 24.5 26 16.5 26C8.5 26 8.5 21 8.5 21V20Z'
        fill='white'
      />
      </svg>
    </div>
  );
};

export default UserIcon;
