const ErrorIcon = () => {
  return (
    <div className='icon-main icon-info'>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 64 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <circle
            cx='32'
            cy='32'
            r='31'
            stroke='#89D4E3'
            strokeWidth='2'
            opacity='0.5'
          />
          <path
            d='M6.81076 8.03385C5.62605 8.03385 4.65674 7.67484 3.90284 6.95684C3.14894 6.23883 2.77198 5.32338 2.77198 4.21047C2.77198 3.06167 3.14894 2.12826 3.90284 1.41026C4.65674 0.692254 5.62605 0.333252 6.81076 0.333252C7.99546 0.333252 8.94682 0.692254 9.66482 1.41026C10.4187 2.12826 10.7957 3.06167 10.7957 4.21047C10.8316 5.32338 10.4726 6.23883 9.71867 6.95684C8.96477 7.67484 7.99546 8.03385 6.81076 8.03385ZM0.833374 29.6278H4.11824V16.2729H0.887224V12.2342H10.6341V29.6278H13.7574V33.6666H0.833374V29.6278Z'
            fill='#89D4E3'
          />
        </g>
      </svg>
    </div>
  );
};

export default ErrorIcon;
