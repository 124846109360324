import i18n from '../../helpers/i18n';

import MoreIcon from '../../icons/MoreIcon';
import RoboLogoIcon from '../../icons/RoboLogoIcon';
import StepIcon from '../../icons/StepIcon';
import SuccessIcon from '../../icons/SuccessIcon';
import UserIcon from '../../icons/UserIcon';

const Success = ({ lang }) => {
  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__title-container'>
          <SuccessIcon />
          <div className='main-banner__title'>
            {i18n.get('acceptProject.successTitle', lang)}
          </div>
        </div>
        <div className='project-accepted-tutorial'>
          <div className='project-accepted-tutorial__step-container'>
            <div className='project-accepted-tutorial__step-description'>
              <StepIcon stepNumber='1' />
              <div>
                {i18n.get('acceptProject.successStep1', lang)}
              </div>
            </div>
            <div className='project-accepted-tutorial__app-open'>
              <RoboLogoIcon />
              <div className='project-accepted-tutorial__app-open__app-name'>
                Robo Wunderkind
              </div>
            </div>
          </div>
          <div className='project-accepted-tutorial__step-container'>
            <div className='project-accepted-tutorial__step-description'>
              <StepIcon stepNumber='2' />
              <div>
                {i18n.get('acceptProject.successStep2', lang)}
              </div>
            </div>
            <div className='project-preview__container'>
              <div className='project-preview__thumbnail-container' />
              <UserIcon />
              <div className='project-preview__project-name-container'>
                <div className='project-preview__project-name'>
                  {i18n.get('acceptProject.successProjectName', lang)}
                </div>
              </div>
              <MoreIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success;
