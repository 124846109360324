import useApi from '../../helpers/useApi';

import NotFound from '../../NotFound';

import LinkExpired from './LinkExpired';
import Success from './Success';

const VerifyEmail = () => {
  const { isLoading, data } = useApi('user/email-verify');

  if (isLoading) {
    return null;
  }

  if (!data.error || data.error.code === 'emailverify-002') {
    return <Success lang={data.lang} />
  } else if (data.error?.code === 'emailverify-003') {
    return <LinkExpired lang={data.lang} />
  } else {
    return <NotFound lang={data.lang} />;
  }
};

export default VerifyEmail;
