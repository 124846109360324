import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Channels = () => {
  const { isLoading, data } = useApi('analytics/marketing/channels');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.channels}
      total={data.total}
      filterOpts={{
        filters: [
          {
            name: 'campaignName',
            type: 'text',
            placeholder: 'Filter by campaign',
          },
        ]
      }}
    />
  );
};

export default Channels;

const columns = [
  {
    key: 'channel',
    label: 'Name',
    isOrderable: true,
  },
  {
    key: 'count_clicks',
    label: 'Clicks',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_converted',
    label: 'Downloads',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
];
