import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Yearly = () => {
  const { isLoading, data } = useApi('analytics/marketing/timeline/yearly');

  return (
    <div className='stats-main-container'>
      <DateFilters selectYears dateFormat={'yyyy'}/>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          <div className='charts-container'>
            <CustomBarChart
              label={`Yearly conversions, total clicks: ${data.conversionsYearly.total || 0}`}
              data={data.conversionsYearly.yearly}
              enumName='clickConversion'
              dataKey='year_start'
              xAxisTickFormatter={formatters.year}
              tooltipLabelFormatter={formatters.year}
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Clicks per OS, total clicks: ${data.clicksPerOsYearly.total || 0}`}
              data={data.clicksPerOsYearly.yearly}
              enumName='osMarketing'
              dataKey='year_start'
              xAxisTickFormatter={formatters.year}
              tooltipLabelFormatter={formatters.year}
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Conversions per OS, total conversions: ${data.conversionsPerOsYearly.total || 0}`}
              data={data.conversionsPerOsYearly.yearly}
              enumName='osMarketing'
              dataKey='year_start'
              xAxisTickFormatter={formatters.year}
              tooltipLabelFormatter={formatters.year}
            />
          </div>
        </>
      )}
    </div>
  )
};

export default Yearly;
