import enums from '../../../helpers/enums';
import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomPie from '../../../components/CustomPie';
import TableInfo from '../../../components/TableInfo';

const Overview = () => {
  const { isLoading, data } = useApi('analytics/apps/project/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const mainInfoRows = [
    {
      label: 'Name',
      value: data.project.name,
    },
    {
      label: 'Type',
      value: enums.projectType[data.project.type].label,
    },
    {
      label: 'App',
      value: enums.app[data.project.app].label,
    },
    {
      label: 'Created at',
      value: formatters.dateTime(data.project.created_at),
    },
    {
      label: 'Time open',
      value: formatters.secondsToDuration(data.project.project_open_duration),
    },
    {
      label: 'Build time',
      value: formatters.secondsToDuration(data.project.project_build_duration),
    },
    {
      label: 'Play time',
      value: formatters.secondsToDuration(data.project.project_play_duration),
    },
    {
      label: 'Time help open',
      value: formatters.secondsToDuration(data.project.help_open_project_duration),
    },
    {
      label: 'Times opened',
      value: data.project.project_open_count,
    },
    {
      label: 'Times played',
      value: data.project.project_play_count,
    },
    {
      label: 'Times help opened',
      value: data.project.help_open_project_count,
    },
    {
      label: 'Avg. time open',
      value: formatters.secondsToDuration(data.project.project_open_duration_avg),
    },
    {
      label: 'Avg. build time',
      value: formatters.secondsToDuration(data.project.project_build_duration_avg),
    },
    {
      label: 'Avg. play time',
      value: formatters.secondsToDuration(data.project.project_play_duration_avg),
    },
    {
      label: 'Avg. time help open',
      value: formatters.secondsToDuration(data.project.help_open_project_duration_avg),
    },
  ].filter(({ value }) => value != null);

  return (
    <div className='stats-main-container'>
      <div className='charts-container'>
        <TableInfo rows={mainInfoRows}/>
        <CustomPie
          title='Build time vs. play time'
          data={[
            {
              duration: data.project.project_build_duration,
              type: 'build',
            },
            {
              duration: data.project.project_play_duration,
              type: 'play',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectAction'
        />
        <CustomPie
          title='Opened with main block conn.'
          data={[
            {
              name: 'yes',
              count: data.project.project_open_count,
            },
            {
              name: 'no',
              count: data.project.project_open_no_system_cube_count,
            },
          ]}
          dataKey='count'
          nameKey='name'
          enumName='mainBlockConnected'
        />
      </div>
    </div>
  );
};

export default Overview;
