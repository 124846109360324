import querystring from 'querystring';
import { useHistory } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Timeline = () => {
  const history = useHistory();

  const { isLoading, data } = useApi('analytics/apps/user-profile/timeline');

  return (
    <div className='stats-main-container'>
      <DateFilters />
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          <div className='charts-container'>
            <CustomBarChart
              label={`Daily usage per app, total time ${formatters.secondsToDuration(data.appUsageDaily.total)}`}
              data={data.appUsageDaily.daily}
              enumName='app'
              onBarClick={({ date }) => history.push({
                pathname: '/analytics/apps/sessions',
                search: querystring.stringify({ username: data.userProfile.username, dateStart: date, dateEnd: date }),
              })}
              yAxisTickFormatter={formatters.secondsToDuration}
              tooltipValueFormatter={formatters.secondsToDuration}
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Project time per project type, total time ${formatters.secondsToDuration(data.projectTypeDurationDaily.total)}`}
              data={data.projectTypeDurationDaily.daily}
              enumName='projectType'
              yAxisTickFormatter={formatters.secondsToDuration}
              tooltipValueFormatter={formatters.secondsToDuration}
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Project build time vs play time, total time ${formatters.secondsToDuration(data.sessionProjectActionDurationDaily.total)}`}
              data={data.sessionProjectActionDurationDaily.daily}
              enumName='projectAction'
              yAxisTickFormatter={formatters.secondsToDuration}
              tooltipValueFormatter={formatters.secondsToDuration}
            />
          </div>
        </>
      )}
    </div>
  )
};

export default Timeline;
