import useApi from '../../helpers/useApi';

import NotFound from '../../NotFound';

import AccountDeleted from './AccountDeleted';
import Expired from './Expired';
import ProjectDeleted from './ProjectDeleted';
import Success from './Success';
import UserBlocked from './UserBlocked';

const AcceptProject = () => {
  const { isLoading, data } = useApi('projects/share/accept');

  if (isLoading) {
    return null;
  }

  if (!data.error || data.error.code === 'project-share-009') {
    return <Success lang={data.lang} />
  } else if (data.error?.code === 'project-share-012') {
    return <AccountDeleted lang={data.lang} />
  } else if (data.error?.code === 'project-share-014') {
    return <Expired lang={data.lang} />
  } else if (data.error?.code === 'project-share-013') {
    return <ProjectDeleted lang={data.lang} />
  } else if (data.error?.code === 'project-share-010') {
    return <UserBlocked lang={data.lang} username={data.username} />
  } else {
    return <NotFound lang={data.lang} />;
  }
};

export default AcceptProject;
