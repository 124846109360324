import {
  Bar,
  BarChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import formatters from '../helpers/formatters';

const CustomBarChart = (props) => {
  const {
    label,
    data,
    onBarClick,
    dataKey = 'group',
    barDataKey = 'count',
    xAxisTickFormatter = (v) => v,
    yAxisTickFormatter = (v) => formatters.percentage(v / total),
    tooltipLabelFormatter = (v) => v,
    tooltipValueFormatter = (v) => v,
    width = 380,
    height = 150,
    total,
  } = props;

  return (
    <div className='chart-container'>
      <h3>{label}</h3>
      <BarChart
        data={data}
        width={width}
        height={height}
      >
        <CartesianGrid strokeDasharray="3 3"/>
        <XAxis
          dataKey={dataKey}
          tickFormatter={xAxisTickFormatter}
        />
        <YAxis
          dataKey='count'
          tickFormatter={yAxisTickFormatter}
          minTickGap={80}
          domain={[0, total]}
        />
        <Tooltip
          formatter={(value, name) => [null, null]}
          labelFormatter={(label, [value]) =>
            `${tooltipLabelFormatter(label)}: ${(value && tooltipValueFormatter(value.payload.count)) || 0}`
          }
        />
        <Bar
          key={dataKey}
          dataKey={barDataKey}
          stackId='a'
          fill='#5d6ecc'
          isAnimationActive={false}
          onClick={onBarClick}
          className={onBarClick ? 'link' : ''}
        />
      </BarChart>
    </div>
  );
};

export default CustomBarChart;
