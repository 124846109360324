import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Yearly = () => {
  const { isLoading, data } = useApi('analytics/production/timeline/yearly');

  return (
    <div className='stats-main-container'>
      <DateFilters selectYears dateFormat={'yyyy'}/>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          {data.testsPerFactoryYearly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per factory, total tests: ${data.testsPerFactoryYearly.total || 0}`}
                  data={data.testsPerFactoryYearly.yearly}
                  enumName='factory'
                  dataKey='year_start'
                  xAxisTickFormatter={formatters.year}
                  tooltipLabelFormatter={formatters.year}
                />
              </div>
            ) : null
          }
          {data.testsPassedVsFailedYearly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Passed vs. failed tests, total tests: ${data.testsPassedVsFailedYearly.total || 0}`}
                  data={data.testsPassedVsFailedYearly.yearly}
                  enumName='testResult'
                  dataKey='year_start'
                  xAxisTickFormatter={formatters.year}
                  tooltipLabelFormatter={formatters.year}
                />
              </div>
            ) : null
          }
          {data.testsPerModuleYearly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per module, total tests: ${data.testsPerModuleYearly.total || 0}`}
                  data={data.testsPerModuleYearly.yearly}
                  enumName='module'
                  dataKey='year_start'
                  xAxisTickFormatter={formatters.year}
                  tooltipLabelFormatter={formatters.year}
                />
              </div>
            ) : null
          }
        </>
      )}
    </div>
  )
};

export default Yearly;
