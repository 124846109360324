import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Modules = () => {
  const { isLoading, data } = useApi('analytics/production/modules');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.modules}
      total={data.total}
      filterOpts={{
        filters: [
          {
            name: 'factories',
            type: 'enum',
            enumName: 'factory',
          },
          {
            name: 'modules',
            type: 'enum',
            enumName: 'module',
          },
          {
            name: 'jigId',
            type: 'text',
            placeholder: 'Filter by Jig UUID',
          },
        ]
      }}
    />
  );
};

export default Modules;

const columns = [
  {
    key: 'id',
    label: 'UUID',
  },
  {
    key: 'module_num',
    label: 'Module',
    formatter: (moduleNum) => enums.module[moduleNum].label,
    isOrderable: true,
  },
  {
    key: 'factory_id',
    label: 'Factory',
    formatter: (factoryId) => enums.factory[factoryId].label,
  },
  {
    key: 'count_tests',
    label: 'Tests',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_tests_passed',
    label: 'Passed',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_tests_failed',
    label: 'Failed',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'avg_data_sample',
    label: 'Avg. data sample',
    formatter: formatters.float,
  },
  {
    key: 'count_jigs',
    label: 'Jigs',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
];
