import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Daily = () => {
  const { isLoading, data } = useApi('analytics/marketing/timeline/daily');

  return (
    <div className='stats-main-container'>
      <DateFilters />
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          <div className='charts-container'>
            <CustomBarChart
              label={`Daily conversions, total clicks: ${data.conversionsDaily.total || 0}`}
              data={data.conversionsDaily.daily}
              enumName='clickConversion'
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Clicks per OS, total clicks: ${data.clicksPerOsDaily.total || 0}`}
              data={data.clicksPerOsDaily.daily}
              enumName='osMarketing'
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Conversions per OS, total conversions: ${data.conversionsPerOsDaily.total || 0}`}
              data={data.conversionsPerOsDaily.daily}
              enumName='osMarketing'
            />
          </div>
        </>
      )}
    </div>
  )
};

export default Daily;
