const MoreIcon = () => {
  return (
    <div className='project-preview__more'>
      <svg
        width='21'
        height='5'
        viewBox='0 0 21 5'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
      <path
        d='M4.94444 2.22222C4.94444 3.44952 3.94952 4.44444 2.72222 4.44444C1.49492 4.44444 0.5 3.44952 0.5 2.22222C0.5 0.994923 1.49492 0 2.72222 0C3.94952 0 4.94444 0.994923 4.94444 2.22222Z'
        fill='#202020'
        fillOpacity='0.9'
      />
      <path
        d='M12.7222 2.22222C12.7222 3.44952 11.7273 4.44444 10.5 4.44444C9.2727 4.44444 8.27778 3.44952 8.27778 2.22222C8.27778 0.994923 9.2727 0 10.5 0C11.7273 0 12.7222 0.994923 12.7222 2.22222Z'
        fill='#202020'
        fillOpacity='0.9'
      />
      <path
        d='M20.5 2.22222C20.5 3.44952 19.5051 4.44444 18.2778 4.44444C17.0505 4.44444 16.0556 3.44952 16.0556 2.22222C16.0556 0.994923 17.0505 0 18.2778 0C19.5051 0 20.5 0.994923 20.5 2.22222Z'
        fill='#202020'
        fillOpacity='0.9'
      />
      </svg>
    </div>
  );
};

export default MoreIcon;
