import axios from 'axios';
import { merge } from 'lodash';
import querystring from 'querystring';
import { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';

const baseRoute = `${process.env.REACT_APP_HOSTNAME}/api`;

const useApi = (path) => {
  const location = useLocation();
  const params = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      let filters = querystring.parse(location.search.slice(1));
      filters = merge(filters, params);

      try {
        const login_token = localStorage.getItem('login_token');
        const result = await axios.post(`${baseRoute}/${path}`, { filters, login_token });
        setData(result.data);
      } catch (error) {
        setData(error.response.data);
      }

      setIsLoading(false);
    };

    fetchData();
  }, [path, location, params]);

  return { isLoading, data };
}

export default useApi;
