import {
  Link,
  Route,
  Switch,
} from 'react-router-dom';

import DefaultFilters from '../../components/DefaultFilters';
import Overview from './Overview';
import Project from './Project';
import Projects from './Projects';
import Session from './Session';
import Sessions from './Sessions';
import Timeline from './Timeline';
import User from './User';
import Users from './Users';
import UserProfile from './UserProfile';
import UserProfiles from './UserProfiles';

const AppAnalytics = () => {
  return (
    <>
      <div className='navigation-container'>
        <Link to='/analytics/apps' className='navigation-item'>Overview</Link>
        <Link to='/analytics/apps/timeline' className='navigation-item'>Timeline</Link>
        <Link to='/analytics/apps/users' className='navigation-item'>Accounts</Link>
        <Link to='/analytics/apps/user-profiles' className='navigation-item'>Profiles</Link>
        <Link to='/analytics/apps/projects' className='navigation-item'>Projects</Link>
        <Link to='/analytics/apps/sessions' className='navigation-item'>Sessions</Link>
      </div>

      <div className='content-container'>
        <Switch>
          <Route exact path='/analytics/apps'>
            <Overview />
          </Route>
          <Route exact path='/analytics/apps/timeline'>
            <DefaultFilters component={<Timeline />} type='timeline'/>
          </Route>
          <Route exact path='/analytics/apps/users'>
            <DefaultFilters component={<Users />} type='users'/>
          </Route>
          <Route path='/analytics/apps/users/:userId'>
            <User />
          </Route>
          <Route exact path='/analytics/apps/user-profiles'>
            <DefaultFilters component={<UserProfiles />} type='userProfiles'/>
          </Route>
          <Route path='/analytics/apps/user-profiles/:userProfileId'>
            <UserProfile />
          </Route>
          <Route exact path='/analytics/apps/projects'>
            <DefaultFilters component={<Projects />} type='projects'/>
          </Route>
          <Route path='/analytics/apps/projects/:projectId'>
            <Project />
          </Route>
          <Route exact path='/analytics/apps/sessions'>
            <DefaultFilters component={<Sessions />} type='sessions'/>
          </Route>
          <Route path='/analytics/apps/sessions/:sessionId'>
            <Session />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default AppAnalytics;
