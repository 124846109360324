import axios from 'axios';
import { useEffect, useState } from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';

import './index.css';

import AppAnalytics from './AppAnalytics';
import MarketingAnalytics from './MarketingAnalytics';
import ProductionAnalytics from './ProductionAnalytics';

const Analytics = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const location = useLocation();

  if (!isLoggedIn) {
    return (
      <Switch>
        <Route exact path='/analytics/login'>
          <LoginForm isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}/>
        </Route>

        <Route path='*'>
          <Redirect
            to={{
              pathname: "/analytics/login",
              state: { from: location }
            }}
          />
        </Route>
      </Switch>
    );
  }

  return (
    <div className='main-container'>
      <div className='navigation-container'>
        <Link to='/analytics/apps' className='navigation-item'>Apps</Link>
        <Link to='/analytics/production' className='navigation-item'>Production</Link>
        <Link to='/analytics/marketing' className='navigation-item'>Marketing</Link>
        <div
          className='navigation-item link'
          onClick={() => {
            localStorage.removeItem('login_token');
            setIsLoggedIn(false);
          }}
        >
          Log out
        </div>
      </div>

      <div className='content-container'>
        <Switch>
          <Route path='/analytics/apps'>
            <AppAnalytics />
          </Route>
          <Route path='/analytics/production'>
            <ProductionAnalytics />
          </Route>
          <Route path='/analytics/marketing'>
            <MarketingAnalytics />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Analytics;

const LoginForm = ({ isLoggedIn, setIsLoggedIn }) => {
  const location = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [credentials, setCredentials] = useState({ username: '', password: '' });

  useEffect(() => {
    const login_token = localStorage.getItem('login_token');
    if (!isLoggedIn && login_token) {
      const doQuickLogin = async () => {
        setIsLoading(true);

        try {
          await axios.post(`${process.env.REACT_APP_HOSTNAME}/api/robo-user/quick-login`, { login_token });
          setIsLoggedIn(true);
          const { from } = location.state || { from: { pathname: '/' } };
          history.replace(from);
        } catch (error) {
          setError(error.response.data.error.message);
          localStorage.removeItem('login_token');
          setIsLoading(false);
        }
      }

      doQuickLogin();
    }
  });

  return (
    <form
      name='form'
      className='login-form'
      onSubmit={async (e) => {
        e.preventDefault();

        setIsLoading(true);
        setError(null);

        try {
          const { data: { login_token } } = await axios.post(`${process.env.REACT_APP_HOSTNAME}/api/robo-user/login`, credentials);
          setIsLoggedIn(true);
          localStorage.setItem('login_token', login_token);
          const { from } = location.state || { from: { pathname: '/' } };
          history.replace(from);
        } catch (error) {
          setError(error.response.data.error.message);
          setIsLoading(false);
        }
      }}
    >
      <input
        placeholder='Username'
        type='text'
        required
        value={credentials.username}
        onChange={(e) => setCredentials((c) => ({ ...c, username: e.target.value }))}
        disabled={isLoading}
      />
      <input
        placeholder='Password'
        type='password'
        required
        value={credentials.password}
        onChange={(e) => setCredentials((c) => ({ ...c, password: e.target.value }))}
        disabled={isLoading}
      />
      <input
        type='submit'
        value='Log in'
        disabled={isLoading}
      />
      <div className='login-form-error'>
        {error}
      </div>
    </form>
  );
};
