import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import DefaultFilters from '../../../components/DefaultFilters';
import Project from '../Project';
import Projects from '../Projects';
import UserProfile from '../UserProfile';
import UserProfiles from '../UserProfiles';
import Session from '../Session';
import Sessions from '../Sessions';

import Overview from './Overview';
import Timeline from './Timeline';

const User = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <h1>Single account statistics</h1>
      <div className='navigation-container'>
        <Link to={`${url}`} className='navigation-item'>Overview</Link>
        <Link to={`${url}/timeline`} className='navigation-item'>Timeline</Link>
        <Link to={`${url}/projects`} className='navigation-item'>Projects</Link>
        <Link to={`${url}/user-profiles`} className='navigation-item'>Profiles</Link>
        <Link to={`${url}/sessions`} className='navigation-item'>Sessions</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/`}>
          <Overview />
        </Route>
        <Route exact path={`${path}/timeline`}>
          <DefaultFilters component={<Timeline />} type='timeline'/>
        </Route>
        <Route exact path={`${path}/projects`}>
          <DefaultFilters component={<Projects />} type='projects'/>
        </Route>
        <Route path={`${path}/projects/:projectId`}>
          <Project />
        </Route>
        <Route exact path={`${path}/user-profiles`}>
          <DefaultFilters component={<UserProfiles />} type='userProfiles'/>
        </Route>
        <Route path={`${path}/user-profiles/:userProfileId`}>
          <UserProfile />
        </Route>
        <Route exact path={`${path}/sessions`}>
          <DefaultFilters component={<Sessions />} type='sessions'/>
        </Route>
        <Route path={`${path}/sessions/:sessionId`}>
          <Session />
        </Route>
      </Switch>
    </>
  );
};

export default User;
