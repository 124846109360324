import * as dateFns from 'date-fns';
import DatePicker from 'react-datepicker';
import { clone, defaults, map, reduce } from 'lodash';
import path from 'path';
import querystring from 'querystring';
import { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';

import enums from '../helpers/enums';

import './Table.css';

const Table = (props) => {
  const location = useLocation();
  const history = useHistory();
  const { url } = useRouteMatch();

  const {
    columns,
    data,
    total,
    disableRowClick,
    filterOpts,
  } = props;

  const filters = querystring.parse(location.search.slice(1))

  return (
    <>
      {filterOpts && filterOpts.filters.length
        ? (<Filters filterOpts={filterOpts}/>)
        : null
      }
      <button
        onClick={() => {
          if (filters.offset > 0) {
            history.push({
              pathname: location.pathname,
              search: querystring.stringify({
                ...filters,
                offset: Number(filters.offset) - Number(filters.limit),
              }),
            });
          }
        }}
      >
        ←
      </button>
      <span className='table-paging-info'>
        Total: <b>{total || 0}</b> -
        Page: <b>{Math.round(Number(filters.offset) / Number(filters.limit)) + 1}
        / {Math.ceil((Number(total) || 1) / Number(filters.limit))}</b>
      </span>
      <button
        onClick={() => {
          if (data[0] && Number(filters.offset) + Number(filters.limit) < total) {
            history.push({
              pathname: location.pathname,
              search: querystring.stringify({
                ...filters,
                offset: Number(filters.offset) + Number(filters.limit),
              }),
            });
          }
        }}
      >
        →
      </button>
      <table className='table-users'>
        <colgroup>
          {columns.map((column) => (
            <col
              key={column.key}
              className={filters.orderBy === column.key ? 'sorted-col' : null}
            />
          ))}
        </colgroup>
        <thead>
          <tr>
            {columns.map((column) => (
              <th
                key={column.key}
                className={column.isOrderable && 'table-header-sortable'}
                onClick={column.isOrderable && (() => changeSorting(location, history, column))}
              >
                {column.label}
                {filters.orderBy === column.key && filters.orderDir === 'ASC' && '↑'}
                {filters.orderBy === column.key && filters.orderDir === 'DESC' && '↓'}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr
              key={index}
              className={`table-users-row ${!disableRowClick ? 'link' : ''}`}
              onClick={() => !disableRowClick && history.push(path.join(url, row.id.toString()))}
            >
              {columns.map((column) => {
                const isEmpty = row[column.key] == null || row[column.key] === '';

                return (
                  <td
                    key={column.key}
                    onClick={column.onClick && !isEmpty ? (() => column.onClick(row)) : null}
                    className={column.onClick && !isEmpty ? 'link' : ''}
                  >
                    {column.formatter ? column.formatter(row[column.key]) : row[column.key]}
                    {!isEmpty && column.onClick && '⇒'}
                  </td>
                )
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default Table;

const changeSorting = (location, history, { key: orderBy, defaultOrderDir = 'ASC' }) => {
  const filters = querystring.parse(location.search.slice(1))

  if (filters.orderBy === orderBy) {
    filters.orderDir = filters.orderDir === 'ASC' ? 'DESC' : 'ASC';
  } else {
    filters.orderBy = orderBy;
    filters.orderDir = defaultOrderDir;
  }

  history.push({
    pathname: location.pathname,
    search: querystring.stringify(filters),
  });
};

const Filters = ({ filterOpts }) => {
  const location = useLocation();
  const history = useHistory();

  const filters = querystring.parse(location.search.slice(1))

  if (filters.dateStart) {
    filters.dateStart = dateFns.parseISO(filters.dateStart);
  }

  if (filters.dateEnd) {
    filters.dateEnd = dateFns.parseISO(filters.dateEnd);
  }

  const localFilterDefaults = {
    userEmail: '',
    teacherEmail: '',
    age: '',
    birthMonth: '',
    birthYear: '',
    name: '',
    fwUpdateFailed: false,
    bleConnIssues: false,
    // production analytics
    jigIdSearch: '',
    moduleIdSearch: '',
    // marketing analytics
    campaignName: '',
    channelName: '',
  };
  const [localFilters, setLocalFilters] = useState(defaults(filters, localFilterDefaults));

  return (
    <div className='filters'>
      {map(filterOpts.filters, (f) => renderFilter(f, localFilters, setLocalFilters))}
      <div className='filter-group'>
        <button
          type='button'
          onClick={() => {
            const updatedFilters = reduce(clone(localFilters), (acc, v, k) => {
              if (v) {
                acc[k] = v;
              }
              return acc;
            }, {});

            if (updatedFilters.dateStart) {
              updatedFilters.dateStart = dateFns.format(updatedFilters.dateStart, 'yyyy-MM-dd');
            }

            if (updatedFilters.dateEnd) {
              updatedFilters.dateEnd = dateFns.format(updatedFilters.dateEnd, 'yyyy-MM-dd');
            }

            history.push({
              pathname: location.pathname,
              search: querystring.stringify(updatedFilters),
            });
          }}
        >
          Filter
        </button>
        <button
          type='button'
          onClick={() => {
            history.push({
              pathname: location.pathname,
            });
          }}
        >
          Clear
        </button>
      </div>
    </div>
  );
};

const renderFilter = (filter, localFilters, setLocalFilters) => {
  switch (filter.type) {
    case 'enum':
      return (
        <div key={filter.name} className='filter-group'>
          {map(enums[filter.enumName], ({ label }, key) => {
            const inputId = `filter-checkbox-${key}`;

            return (
              <div key={key}>
                <input
                  id={inputId}
                  type='checkbox'
                  checked={localFilters[filter.name].includes(key)}
                  onChange={(e) => setLocalFilters((f) => {
                    let enumFiltersUpdated = [localFilters[filter.name]].flat();
                    if (e.target.checked && !enumFiltersUpdated.includes(key)) {
                      enumFiltersUpdated.push(key);
                    } else {
                      enumFiltersUpdated = enumFiltersUpdated.filter((k) => k !== key);
                    }
                    return { ...f, [filter.name]: enumFiltersUpdated };
                  })}
                />
                <label htmlFor={inputId}>{label}</label>
              </div>
            );
          })}
        </div>
      );
    case 'text':
      return (
        <div key={filter.name} className='filter-group'>
          <input
            type='text'
            placeholder={filter.placeholder}
            value={localFilters[filter.name]}
            onChange={(e) => setLocalFilters((f) => ({ ...f, [filter.name]: e.target.value }))}
          />
        </div>
      );
    case 'textGroup':
      return (
        <div key={filter.name} className='filter-group'>
          {map(filter.filters, (subfilter) => (
            <input
              key={subfilter.name}
              type='text'
              placeholder={subfilter.placeholder}
              value={localFilters[subfilter.name]}
              onChange={(e) => setLocalFilters((f) => ({ ...f, [subfilter.name]: e.target.value }))}
            />
          ))}
        </div>
      );
    case 'numberGroup':
      return (
        <div key={filter.name} className='filter-group'>
          {map(filter.filters, (subfilter) => (
            <input
              key={subfilter.name}
              type='number'
              min={subfilter.min}
              max={subfilter.max}
              placeholder={subfilter.placeholder}
              value={localFilters[subfilter.name]}
              onChange={(e) => setLocalFilters((f) => ({ ...f, [subfilter.name]: e.target.value }))}
            />
          ))}
        </div>
      );
    case 'checkboxGroup':
      return (
        <div key={filter.name} className='filter-group'>
          {map(filter.filters, (subfilter) => (
            <div key={subfilter.name}>
              <input
                id={subfilter.name}
                type='checkbox'
                checked={localFilters[subfilter.name]}
                onChange={(e) => setLocalFilters((f) => ({ ...f, [subfilter.name]: e.target.checked }))}
              />
              <label htmlFor={subfilter.name}>{subfilter.label}</label>
            </div>
          ))}
        </div>
      );
    case 'dateRange':
      return (
        <div key={filter.name} className='filter-group'>
          <DatePicker
            placeholderText='Started from'
            dateFormat='yyyy-MM-dd'
            selected={localFilters.dateStart || null}
            onChange={(dateStart) => setLocalFilters((f) => ({ ...f, dateStart }))}
            selectsStart
            startDate={localFilters.dateStart || null}
            endDate={localFilters.dateEnd || null}
          />
          <DatePicker
            placeholderText='Started until'
            dateFormat='yyyy-MM-dd'
            selected={localFilters.dateEnd || null}
            onChange={(dateEnd) => setLocalFilters((f) => ({ ...f, dateEnd }))}
            selectsEnd
            startDate={localFilters.dateStart || null}
            endDate={localFilters.dateEnd || null}
            minDate={localFilters.dateStart || null}
          />
        </div>
      );
    default:
      return null;
  }
};
