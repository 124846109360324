import i18n from './helpers/i18n';

import ErrorIcon from './icons/ErrorIcon';

const NotFound = ({ lang }) => {
  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__title-container'>
          <ErrorIcon />
          <div className='main-banner__title'>
            {i18n.get('notFound.title', lang)}
          </div>
          <div className='main-banner__subtitle'>
            {i18n.get('notFound.subtitle', lang)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
