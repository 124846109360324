import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import DefaultFilters from '../../../components/DefaultFilters';

import Overview from './Overview';
import Timeline from './Timeline';

const Project = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <h1>Single project statistics</h1>
      <div className='navigation-container'>
        <Link to={`${url}`} className='navigation-item'>Overview</Link>
        <Link to={`${url}/timeline`} className='navigation-item'>Timeline</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/`}>
          <Overview />
        </Route>
        <Route exact path={`${path}/timeline`}>
          <DefaultFilters component={<Timeline />} type='timeline'/>
        </Route>
      </Switch>
    </>
  );
};

export default Project;
