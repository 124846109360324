import querystring from 'querystring';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import CustomPie from '../../components/CustomPie';
import TableInfo from '../../components/TableInfo';

import Timeline from './Timeline';

const Channel = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <h1>Single channel statistics</h1>
      <div className='navigation-container'>
        <Link to={`${url}`} className='navigation-item'>Overview</Link>
        <Link to={`${url}/timeline`} className='navigation-item'>Timeline</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/`}>
          <Overview />
        </Route>
        <Route path={`${path}/timeline`}>
          <Timeline />
        </Route>
      </Switch>
    </>
  );
};

export default Channel;

const Overview = () => {
  const history = useHistory();

  const { isLoading, data } = useApi('analytics/marketing/channel/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const rows = [
    {
      label: 'Channel name',
      value: data.channelStats.channel,
    },
    {
      label: 'Clicks',
      value: data.channelStats.count_clicks,
    },
    {
      label: 'Downloads',
      value: data.channelStats.count_converted,
    },
    {
      label: 'Conversion percentage',
      value: formatters.percentage(data.channelStats.count_converted / data.channelStats.count_clicks),
    },
    {
      label: 'Campaigns',
      value: data.channelStats.count_campaigns,
      onClick: () => history.push({
        pathname: '/analytics/marketing/campaigns',
        search: querystring.stringify({ channelName: data.channelStats.channel }),
      }),
    },
  ];

  return (
    <>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            rows={rows}
          />
          <CustomPie
            title='Conversions'
            data={[
              {
                name: 'converted',
                count: data.channelStats.count_converted,
              },
              {
                name: 'unconverted',
                count: data.channelStats.count_clicks - data.channelStats.count_converted,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='clickConversion'
          />
          <CustomPie
            title='Clicks per OS'
            data={[
              {
                name: 'android',
                count: data.channelStats.count_clicks_android,
              },
              {
                name: 'ios',
                count: data.channelStats.count_clicks_android,
              },
              {
                name: 'windows',
                count: data.channelStats.count_clicks_windows,
              },
              {
                name: 'macos',
                count: data.channelStats.count_clicks_macos,
              },
              {
                name: 'unknown',
                count: data.channelStats.count_clicks_unknown,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='osMarketing'
          />
          <CustomPie
            title='Conversions per OS'
            data={[
              {
                name: 'android',
                count: data.channelStats.count_converted_android,
              },
              {
                name: 'ios',
                count: data.channelStats.count_converted_ios,
              },
              {
                name: 'windows',
                count: data.channelStats.count_converted_windows,
              },
              {
                name: 'macos',
                count: data.channelStats.count_converted_macos,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='osMarketing'
          />
        </div>
      </div>
    </>
  );
};
