import { useHistory, useParams } from 'react-router-dom';

import i18n from '../../helpers/i18n';

const ConfirmBlockUser = ({ lang, username }) => {
  const history = useHistory();
  const params = useParams();

  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__title-container'>
          <div className='main-banner__title'>
            {i18n.get('blockUser.confirmationTitle', lang, { username })}
          </div>
          <div className='main-banner__subtitle'>
            {i18n.get('blockUser.confirmationDescription', lang, { username })}
          </div>
          <div
            className='button-main'
            onClick={() => history.push(`/block-sharer/${params.hash}`)}
          >
            {i18n.get('blockUser.confirmationButton', lang)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBlockUser;
