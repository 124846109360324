import querystring from 'querystring';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import CustomPie from '../../components/CustomPie';
import TableInfo from '../../components/TableInfo';

import Timeline from './Timeline';

const PhysicalModule = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <h1>Single physical module statistics</h1>
      <div className='navigation-container'>
        <Link to={`${url}`} className='navigation-item'>Overview</Link>
        <Link to={`${url}/timeline`} className='navigation-item'>Timeline</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/`}>
          <Overview />
        </Route>
        <Route path={`${path}/timeline`}>
          <Timeline />
        </Route>
      </Switch>
    </>
  );
};

export default PhysicalModule;

const Overview = () => {
  const history = useHistory();

  const { isLoading, data } = useApi('analytics/production/physical-module/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const rows = [
    {
      label: 'Number',
      value: data.physicalModule.id,
    },
    {
      label: 'Name',
      value: enums.module[data.physicalModule.id].label,
    },
    {
      label: 'Total tests',
      value: data.physicalModule.count_tests,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ modules: [data.physicalModule.id] }),
      }),
    },
    {
      label: 'Passed tests',
      value: data.physicalModule.count_tests_passed,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ modules: [data.physicalModule.id], testResult: ['passed'] }),
      }),
    },
    {
      label: 'Failed tests',
      value: data.physicalModule.count_tests_failed,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ modules: [data.physicalModule.id], testResult: ['failed'] }),
      }),
    },
    {
      label: 'Avg. data sample',
      value: formatters.float(data.physicalModule.avg_data_sample),
    },
    {
      label: 'Factory count',
      value: data.physicalModule.count_factories,
    },
    {
      label: 'Jig count',
      value: data.physicalModule.count_jigs,
      onClick: () => history.push({
        pathname: '/analytics/production/jigs',
        search: querystring.stringify({ modules: [data.physicalModule.id] }),
      }),
    },
    {
      label: 'Modules count',
      value: data.physicalModule.count_modules,
      onClick: () => history.push({
        pathname: '/analytics/production/modules',
        search: querystring.stringify({ modules: [data.physicalModule.id] }),
      }),
    },
    {
      label: 'Avg. tests per test module',
      value: formatters.float(data.physicalModule.avg_tests_per_module),
    },
  ];

  return (
    <>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo rows={rows} />
          <CustomPie
            title='Passed vs. failed tests'
            data={[
              {
                name: 'passed',
                count: data.physicalModule.count_tests_passed,
              },
              {
                name: 'failed',
                count: data.physicalModule.count_tests_failed,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='testResult'
          />
        </div>
      </div>
    </>
  );
};
