import querystring from 'querystring';
import { useHistory } from 'react-router-dom';

import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import CustomPie from '../../components/CustomPie';
import TableInfo from '../../components/TableInfo';

import { BatchHelper } from "../../components/BatchHelper";
import { BatchContext } from '../../context/batchContext';
import { useContext } from 'react';


const Overview = () => {
  const history = useHistory();

  const {batch} = useContext(BatchContext);

  const { isLoading, data } = useApi('analytics/production/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const rows = [
    {
      label: 'Total tests',
      value: data.statsOverview.count_tests,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({...batch}),
      }),
    },
    {
      label: 'Passed tests',
      value: data.statsOverview.count_tests_passed,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ testResult: ['passed'], ...batch }),
      }),
    },
    {
      label: 'Failed tests',
      value: data.statsOverview.count_tests_failed,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ testResult: ['failed'], ...batch }),
      }),
    },
    {
      label: 'Total modules tested',
      value: data.statsOverview.count_devices ,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({...batch}),
      }),
    },
    {
      label: 'Passed modules',
      value: data.statsOverview.count_devices_passed,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ testResult: ['passed'], ...batch }),
      }),
    },
    {
      label: 'Failed modules',
      value: data.statsOverview.count_devices_failed ,
      onClick: () => history.push({
        pathname: '/analytics/production/events',
        search: querystring.stringify({ testResult: ['failed'], ...batch }),
      }),
    },
    {
      label: 'Jig count',
      value: data.statsOverview.count_jigs,
      onClick: () => history.push({
        pathname: '/analytics/production/jigs',
      }),
    },
    {
      label: 'Module count',
      value: data.statsOverview.count_modules,
      onClick: () => history.push({
        pathname: '/analytics/production/modules',
      }),
    },
    {
      label: 'Avg. tests per test module',
      value: formatters.float(data.statsOverview.avg_tests_per_module),
    },
  ];

  return (
    <>
      <h1>Global overview</h1>

      <div className='stats-main-container'>
        <div className='charts-container'>
          <BatchHelper />

          <TableInfo rows={rows} />

          <CustomPie
            title='Passed vs. failed tests'
            data={[
              {
                name: 'passed',
                count: data.statsOverview.count_tests_passed,
              },
              {
                name: 'failed',
                count: data.statsOverview.count_tests_failed,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='testResult'
          />

          <CustomPie
              title='Passed modules vs. failed modules'
              data={[
                {
                  name: 'passed',
                  count: data.statsOverview.count_devices_passed,
                },
                {
                  name: 'failed',
                  count: data.statsOverview.count_devices_failed,
                },
              ]}
              dataKey='count'
              nameKey='name'
              enumName='devicesResult'
          />

          <CustomPie
            title='Tests per factory'
            data={data.statsOverviewPerFactory}
            dataKey='count'
            nameKey='factory_id'
            enumName='factory'
          />

          <CustomPie
            title='Tests per module'
            data={data.statsOverviewPerModule}
            dataKey='count'
            nameKey='module_num'
            enumName='module'
          />
        </div>
      </div>
    </>
  );
};

export default Overview;
