import { get } from 'lodash';

const ar = require('./ar');
const de = require('./de');
const en = require('./en');

const langs = {
  ar,
  de,
  en,
};

const i18n = {};

i18n.get = (key, lang = 'en', vars = {}) => {
  // Always default to English if lang or key is missing
  let translation = get(langs[lang], key, get(langs.en, key));

  Object.keys(vars).forEach((varKey) => {
    translation = translation.replace(new RegExp(`{{${varKey}}}`), vars[varKey]);
  });

  return (
    <div
      dangerouslySetInnerHTML={{ __html: translation }}
      dir={lang === 'ar' ? 'rtl' : 'auto'}
    />
  );
};

export default i18n;
