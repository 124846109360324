import { useState } from 'react';

const Info = () => {
  const [campaign, setCampaign] = useState('');
  const [channel, setChannel] = useState('');

  const renderUrl = (campaign, channel) => {
    const paramRegexp = /^[a-zA-Z0-9-_~.]*$/;
    if (!paramRegexp.test(campaign) || !paramRegexp.test(channel)) {
      return 'Invalid parameters';
    }

    const baseUrl = 'https://analytics.robowunderkind.com';

    if (campaign && channel) {
      return `${baseUrl}?campaign=${campaign}&channel=${channel}`;
    } else if (campaign && !channel) {
      return `${baseUrl}?campaign=${campaign}`;
    } else if (!campaign && channel) {
      return `${baseUrl}?channel=${channel}`;
    } else {
      return baseUrl;
    }
  };

  return (
    <>
      <h1>Info</h1>
      <div>
        <h3>How clicks are tracked</h3>
        <p>
          Dynamic links lead to a backend API route which redirects to an app store depending on user's operating system (based on 'user-agent' header).
          <br/>
          Each access creates a log file containing IP address, country, OS and optionally campaign and channel.
          <br/>
          Optional query parameters make it possible to determine which channel the click came from as well as to which marketing campaign it belongs.
        </p>
        <h3>How conversions are calculated</h3>
        <p>
          Calculating conversions is based on cross-referencing session data from the app.
          <br/>
          For each click, a corresponding session is looked for.
          <br/>
          A click is considered converted if there exists a session which:
        </p>
        <ul>
          <li>has the same IP address</li>
          <li>has the same operating system</li>
          <li>was created within 5 days after click's creation</li>
        </ul>
        <p>
          Multiple clicks with the same IP address + OS + campaign + channel are considered a single click.
        </p>
        <h3>How to use optional query parameters</h3>
        <p>
          Campaign and channel may be used as query parameters.
          <br/>
          They can only contain letters, numbers, and characters ., _, -, ~.
        </p>
        <input
          type='text'
          placeholder='Campaign name'
          max='256'
          value={campaign}
          onChange={(e) => setCampaign(e.target.value)}
        />
        <input
          type='text'
          placeholder='Channel name'
          max='256'
          value={channel}
          onChange={(e) => setChannel(e.target.value)}
        />
        <br/>
        <p>
          <b>{renderUrl(campaign, channel)}</b>
        </p>
      </div>
    </>
  );
};

export default Info;
