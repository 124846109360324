import { Route, Switch } from 'react-router-dom';

import './App.css';

import AcceptProject from './EmailActions/AcceptProject';
import Analytics from './Analytics';
import BlockUserConfirmation from './EmailActions/BlockUserConfirmation';
import BlockUserResult from './EmailActions/BlockUserResult';
import VerifyEmail from './EmailActions/VerifyEmail';
import DemoViews from './DemoViews';
import NotFound from './NotFound';

const App = () => {
  return (
    <div>
      <Switch>
        <Route path='/analytics'>
          <Analytics />
        </Route>

        <Route exact path='/verify-email/:hash'>
          <VerifyEmail />
        </Route>
        <Route exact path='/accept-project/:hash'>
          <AcceptProject />
        </Route>
        <Route exact path='/block-sharer/confirm/:hash'>
          <BlockUserConfirmation />
        </Route>
        <Route exact path='/block-sharer/:hash'>
          <BlockUserResult />
        </Route>

        {(process.env.NODE_ENV === 'development' || process.env.REACT_APP_HOSTNAME === 'https://dev.analytics.robowunderkind.com') && (
          <Route path='/demo-views'>
            <DemoViews />
          </Route>
        )}

        <Route path='*'>
          <NotFound />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
