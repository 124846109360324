import { createContext } from 'react'

export const BatchContext = createContext({
  batches: {
    allBatches: {
      id: 'allBatches',
      name: 'All',
      dateStart: null,
      dateEnd: null
    }
  },
  setBatches: (batches) => {},
  batch: {},
  setBatch: (b) => {}
});
