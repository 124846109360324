import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Weekly = () => {
  const { isLoading, data } = useApi('analytics/marketing/timeline/weekly');

  return (
    <div className='stats-main-container'>
      <DateFilters selectWeeks/>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          <div className='charts-container'>
            <CustomBarChart
              label={`Weekly conversions, total clicks: ${data.conversionsWeekly.total || 0}`}
              data={data.conversionsWeekly.weekly}
              enumName='clickConversion'
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Clicks per OS, total clicks: ${data.clicksPerOsWeekly.total || 0}`}
              data={data.clicksPerOsWeekly.weekly}
              enumName='osMarketing'
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Conversions per OS, total conversions: ${data.conversionsPerOsWeekly.total || 0}`}
              data={data.conversionsPerOsWeekly.weekly}
              enumName='osMarketing'
            />
          </div>
        </>
      )}
    </div>
  )
};

export default Weekly;
