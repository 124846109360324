import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Monthly = () => {
  const { isLoading, data } = useApi('analytics/marketing/timeline/monthly');

  return (
    <div className='stats-main-container'>
      <DateFilters selectMonths dateFormat={'yyyy-MM'}/>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          <div className='charts-container'>
            <CustomBarChart
              label={`Monthly conversions, total clicks: ${data.conversionsMonthly.total || 0}`}
              data={data.conversionsMonthly.monthly}
              enumName='clickConversion'
              dataKey='month_start'
              xAxisTickFormatter={formatters.monthShort}
              tooltipLabelFormatter={formatters.month}
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Clicks per OS, total clicks: ${data.clicksPerOsMonthly.total || 0}`}
              data={data.clicksPerOsMonthly.monthly}
              enumName='osMarketing'
              dataKey='month_start'
              xAxisTickFormatter={formatters.monthShort}
              tooltipLabelFormatter={formatters.month}
            />
          </div>
          <div className='charts-container'>
            <CustomBarChart
              label={`Conversions per OS, total conversions: ${data.conversionsPerOsMonthly.total || 0}`}
              data={data.conversionsPerOsMonthly.monthly}
              enumName='osMarketing'
              dataKey='month_start'
              xAxisTickFormatter={formatters.monthShort}
              tooltipLabelFormatter={formatters.month}
            />
          </div>
        </>
      )}
    </div>
  )
};

export default Monthly;
