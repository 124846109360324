const SuccessIcon = () => {
  return (
    <div className='icon-main'>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 64 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <circle
            cx='32'
            cy='32'
            r='31'
            stroke='#92DDA9'
            strokeWidth='2'
          />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M37.8452 7.36316C38.496 8.01403 38.496 9.0693 37.8452 9.72018L14.9285 32.6368C14.2776 33.2877 13.2224 33.2877 12.5715 32.6368L2.15481 22.2202C1.50394 21.5693 1.50394 20.514 2.15481 19.8632C2.80569 19.2123 3.86096 19.2123 4.51183 19.8632L13.75 29.1013L35.4881 7.36316C36.139 6.71228 37.1943 6.71228 37.8452 7.36316Z'
            fill='#36AD58'
          />
        </g>
      </svg>
    </div>
  );
};

export default SuccessIcon;
