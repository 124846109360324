import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Monthly = () => {
  const { isLoading, data } = useApi('analytics/production/timeline/monthly');

  return (
    <div className='stats-main-container'>
      <DateFilters selectMonths dateFormat={'yyyy-MM'}/>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          {data.testsPerFactoryMonthly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per factory, total tests: ${data.testsPerFactoryMonthly.total || 0}`}
                  data={data.testsPerFactoryMonthly.monthly}
                  enumName='factory'
                  dataKey='month_start'
                  xAxisTickFormatter={formatters.monthShort}
                  tooltipLabelFormatter={formatters.month}
                />
              </div>
            ) : null
          }
          {data.testsPassedVsFailedMonthly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Passed vs. failed tests, total tests: ${data.testsPassedVsFailedMonthly.total || 0}`}
                  data={data.testsPassedVsFailedMonthly.monthly}
                  enumName='testResult'
                  dataKey='month_start'
                  xAxisTickFormatter={formatters.monthShort}
                  tooltipLabelFormatter={formatters.month}
                />
              </div>
            ) : null
          }
          {data.testsPerModuleMonthly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per module, total tests: ${data.testsPerModuleMonthly.total || 0}`}
                  data={data.testsPerModuleMonthly.monthly}
                  enumName='module'
                  dataKey='month_start'
                  xAxisTickFormatter={formatters.monthShort}
                  tooltipLabelFormatter={formatters.month}
                />
              </div>
            ) : null
          }
        </>
      )}
    </div>
  )
};

export default Monthly;
