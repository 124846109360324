import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const UserProfiles = () => {
  const { isLoading, data } = useApi('analytics/apps/user-profiles');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.userProfiles}
      total={data.userProfiles.length ? data.userProfiles[0].total : 0}
      filterOpts={{
        filters: [
          {
            name: 'userProfileStatuses',
            type: 'enum',
            enumName: 'userProfileStatus',
          },
          {
            name: 'usernameemailSearchTextGroup',
            type: 'textGroup',
            filters: [
              {
                name: 'userEmail',
                placeholder: 'Filter by user email',
              },
              {
                name: 'username',
                placeholder: 'Filter by username',
              },
            ],
          },
          {
            name: 'userProfileAgeGroups',
            type: 'enum',
            enumName: 'userProfileAgeGroup',
          },
          {
            name: 'ageNumberGroup',
            type: 'numberGroup',
            filters: [
              {
                name: 'age',
                placeholder: 'Age',
                min: 0,
              },
              {
                name: 'birthMonth',
                placeholder: 'Birth month',
                min: 1,
                max: 12,
              },
              {
                name: 'birthYear',
                placeholder: 'Birth year',
                min: 1900,
                max: (new Date()).getUTCFullYear(),
              },
            ],
          },
        ]
      }}
    />
  );
};

export default UserProfiles;


const columns = [
  {
    key: 'user_email',
    label: 'User email',
    isOrderable: true,
  },
  {
    key: 'username',
    label: 'Username',
    isOrderable: true,
  },
  {
    key: 'status',
    label: 'Status',
    formatter: (status) => enums.userProfileStatus[status].label,
  },
  {
    key: 'age_group',
    label: 'Age group',
    formatter: (ageGroup) => ageGroup && enums.userProfileAgeGroup[ageGroup].label,
    isOrderable: true,
  },
  {
    key: 'age',
    label: 'Age',
    isOrderable: true,
  },
  {
    key: 'birth_month',
    label: 'Birth month',
    isOrderable: true,
  },
  {
    key: 'birth_year',
    label: 'Birth year',
    isOrderable: true,
  },
  {
    key: 'project_upload_count',
    label: 'Synced projects',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'project_share_count',
    label: 'Project shares',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'project_share_unique_recipients_count',
    label: 'Unique recipients',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'project_share_recipient_count',
    label: 'Projects received',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'allow_project_receiving',
    label: 'Allows receiving',
    formatter: formatters.booleanYesNo,
  },
  {
    key: 'project_receive_auto_add',
    label: 'Auto-adds sharers',
    formatter: formatters.booleanYesNo,
  },
  {
    key: 'allow_project_sharing',
    label: 'Allows sharing',
    formatter: formatters.booleanYesNo,
  },
  {
    key: 'created_at',
    label: 'Created at',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.date,
  },
  {
    key: 'created_from',
    label: 'Created from',
    formatter: (createdFrom) => createdFrom && enums.userProfileSource[createdFrom].label,
  },
  {
    key: 'session_duration',
    label: 'Time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
  {
    key: 'session_duration_last_10_days',
    label: 'Time in last 10 days',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
  {
    key: 'session_duration_avg',
    label: 'Avg. session time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
  {
    key: 'system_cube_duration_avg',
    label: 'Avg. main block conn. time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
];
