import axios from 'axios';
import { merge } from 'lodash';
import querystring from 'querystring';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Users = () => {
  const location = useLocation();
  const params = useParams();
  const [isExportInProgress, setIsExportInProgress] = useState(false);

  const { isLoading, data } = useApi('analytics/apps/users');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <>
      <button
        className='button__export'
        onClick={async () => {
          setIsExportInProgress(true);

          let filters = querystring.parse(location.search.slice(1));
          filters = merge(filters, params);
          filters.export_format = 'csv';

          const login_token = localStorage.getItem('login_token');
          const result = await axios.post(`${process.env.REACT_APP_HOSTNAME}/api/users/export`, { filters, login_token });

          setIsExportInProgress(false);

          const response = await axios({
            url: `${process.env.REACT_APP_HOSTNAME}/${result.data.path}`,
            method: 'GET',
            responseType: 'blob',
          })

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users_export.csv');
          document.body.appendChild(link);
          link.click();
        }}
        disabled={isExportInProgress}
      >
        Export as CSV
      </button>
      <button
        className='button__export'
        onClick={async () => {
          setIsExportInProgress(true);

          let filters = querystring.parse(location.search.slice(1));
          filters = merge(filters, params);
          filters.export_format = 'xlsx';

          const login_token = localStorage.getItem('login_token');
          const result = await axios.post(`${process.env.REACT_APP_HOSTNAME}/api/users/export`, { filters, login_token });

          setIsExportInProgress(false);

          const response = await axios({
            url: `${process.env.REACT_APP_HOSTNAME}/${result.data.path}`,
            method: 'GET',
            responseType: 'blob',
          })

          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'users_export.xlsx');
          document.body.appendChild(link);
          link.click();
        }}
        disabled={isExportInProgress}
      >
        Export as XLSX
      </button>
      <Table
        columns={columns}
        data={data.users}
        total={data.users.length ? data.users[0].total : 0}
        filterOpts={{
          filters: [
            {
              name: 'userTypes',
              type: 'enum',
              enumName: 'userType',
            },
            {
              name: 'supportCheckboxGroup',
              type: 'checkboxGroup',
              filters: [
                {
                  name: 'fwUpdateFailed',
                  label: 'FW update failed',
                },
                {
                  name: 'bleConnIssues',
                  label: 'BLE conn. issues',
                },
              ],
            },
            {
              name: 'emailSearchTextGroup',
              type: 'textGroup',
              filters: [
                {
                  name: 'userEmail',
                  placeholder: 'Filter by email',
                },
                {
                  name: 'teacherEmail',
                  placeholder: 'Filter by teacher email',
                },
              ],
            },
          ]
        }}
      />
    </>
  );
};

export default Users;

const columns = [
  {
    key: 'email',
    label: 'Email',
    isOrderable: true,
  },
  {
    key: 'type',
    label: 'Type',
    formatter: (type) => enums.userType[type].label,
  },
  {
    key: 'teacher_email',
    label: 'Teacher email',
    isOrderable: true,
  },
  {
    key: 'user_profile_count_active',
    label: 'Active profiles',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'user_profile_count',
    label: 'Profiles created',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'user_profile_switch_count',
    label: 'Profile switches',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'project_upload_count',
    label: 'Synced projects',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'subscribed',
    label: 'Subscribed',
    formatter: formatters.booleanYesNo,
  },
  {
    key: 'has_fw_update_failed',
    label: 'FW update failed',
    formatter: formatters.booleanYesNo,
  },
  {
    key: 'has_ble_connection_issues',
    label: 'BLE conn. issues',
    formatter: formatters.booleanYesNo,
  },
  {
    key: 'created_at',
    label: 'Registered at',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.date,
  },
  {
    key: 'session_duration',
    label: 'Time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
  {
    key: 'session_duration_last_10_days',
    label: 'Time in last 10 days',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
  {
    key: 'session_duration_avg',
    label: 'Avg. session time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
  {
    key: 'system_cube_duration_avg',
    label: 'Avg. main block conn. time',
    isOrderable: true,
    defaultOrderDir: 'DESC',
    formatter: formatters.duration,
  },
];
