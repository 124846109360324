import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import CustomBarChartInline from '../../components/CustomBarChartInline';
import CustomPie from '../../components/CustomPie';
import TableInfo from '../../components/TableInfo';

const Overview = () => {
  const { isLoading, data } = useApi('analytics/apps/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const usersRows = [
    {
      label: 'Registered accounts',
      value: data.usersOverview.total,
    },
    {
      label: 'Average age',
      value: data.usersOverview.age_avg,
    },
  ];

  const userProfileRows = [
    {
      label: 'Users',
      value: data.usersOverview.count_using_profiles,
    },
    {
      label: 'Profiles active',
      value: data.userProfilesOverview.count_active,
    },
    {
      label: 'Profiles created',
      value: data.userProfilesOverview.count_all,
    },
    {
      label: 'Avg. profiles per account',
      value: formatters.float(data.userProfilesOverview.count_active / data.usersOverview.count_using_profiles),
    },
  ];

  const projectSharingRows = [
    {
      label: 'Project shares',
      value: data.projectSharingOverview.total,
    },
    {
      label: 'Avg. project shares',
      value: formatters.float(data.projectSharingOverview.total / data.userProfilesOverview.count_all),
    },
  ];

  const projectUploadRows = [
    {
      label: 'Avg. synced projects',
      value: formatters.float(data.projectUploadsOverview.total / data.userProfilesOverview.count_active),
    },
  ];

  const connectionRows = [
    {
      label: 'Avg. main block conn. time',
      value: formatters.duration(data.statsOverview.system_cube_duration_avg),
    },
    {
      label: 'Avg. FW update time',
      value: formatters.duration(data.statsOverview.firmware_update_success_duration_avg),
    },
  ];

  const sessionRows = [
    {
      label: 'Sessions',
      value: data.statsOverview.session_count,
    },
    {
      label: 'Total time',
      value: formatters.duration(data.statsOverview.session_duration),
    },
    {
      label: 'Average session length',
      value: formatters.duration(data.statsOverview.session_duration_avg),
    },
    {
      label: 'Sessions in last 10 days',
      value: data.statsOverview.session_count_last_10_days,
    },
    {
      label: 'Time in last 10 days',
      value: formatters.duration(data.statsOverview.session_duration_last_10_days),
    },
  ].filter(({ value }) => value != null);

  const projectRows = [
    {
      label: 'Total time',
      value: formatters.secondsToDuration(data.statsOverview.project_open_duration),
    },
    {
      label: 'Build time',
      value: formatters.secondsToDuration(data.statsOverview.project_build_duration),
    },
    {
      label: 'Play time',
      value: formatters.secondsToDuration(data.statsOverview.project_play_duration),
    },
    {
      label: 'Custom projects created',
      value: data.statsOverview.projects_created_count,
    },
  ];

  const helpUsageRows = [
    {
      label: 'Time (main menu)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_main_menu_duration),
    },
    {
      label: 'Time (charging)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_charging_duration),
    },
    {
      label: 'Time (FW update)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_firmware_update_duration),
    },
    {
      label: 'Time (projects)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_project_duration),
    },
    {
      label: 'Avg. time (main menu)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_main_menu_duration_avg),
    },
    {
      label: 'Avg. time (charging)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_charging_duration_avg),
    },
    {
      label: 'Avg. time (FW update)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_firmware_update_duration_avg),
    },
    {
      label: 'Avg. time (projects)',
      value: formatters.secondsToDuration(data.statsOverview.help_open_project_duration_avg),
    },
  ];

  return (
    <>
      <h1>Global overview</h1>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Registered accounts'
            rows={usersRows}
          />
          <CustomPie
            title='Registrations per account type'
            data={data.usersPerType}
            dataKey='count'
            nameKey='type'
            enumName='userType'
          />
          <CustomPie
            title='Time per account type'
            data={data.sessionDurationPerUserType}
            dataKey='duration'
            nameKey='type'
            enumName='userType'
          />
          <CustomPie
            title='Time registered vs. unregistered'
            data={data.sessionDurationRegVsUnreg}
            dataKey='duration'
            nameKey='type'
            enumName='regType'
          />
          <CustomPie
            title='Susbcribed reg. accounts'
            data={[
              {
                name: 'yes',
                count: data.usersOverview.subscribed_count,
              },
              {
                name: 'no',
                count: data.usersOverview.unsubscribed_count,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoPositive'
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Multiple profiles'
            rows={userProfileRows}
          />
          <CustomBarChartInline
            label='Active profiles per account'
            data={data.activeUserProfilesPerUser}
            dataKey='group'
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.usersOverview.count_using_profiles) + ` - ${v} account(s)`}
            tooltipLabelFormatter={(v) => v + ' active profile(s)'}
            total={data.usersOverview.count_using_profiles}
          />
          <CustomBarChartInline
            label='Profile switches per account'
            data={data.userProfileSwitchUsageGroups}
            dataKey='group'
            xAxisTickFormatter={(v) => v === 1 ? '<10' : v}
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.usersOverview.count_using_profiles) + ` - ${v} account(s)`}
            tooltipLabelFormatter={(v) => {
              if (v === 1) {
                return 'Less than 10 profile switches';
              } else if (v)
                return v + ' profile switches';
              else {
                return 'No profile switches';
              }
            }}
            total={data.usersOverview.count_using_profiles}
          />
          <CustomPie
            title='Age groups'
            data={data.userProfileAgeGroupsUsage}
            dataKey='count'
            nameKey='age_group'
            enumName='userProfileAgeGroup'
          />
          <CustomPie
            title='Profile creation source'
            data={data.userProfileSources}
            dataKey='count'
            nameKey='created_from'
            enumName='userProfileSource'
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Parental controls'
            rows={[]}
          />
          <CustomPie
            title='Allows receiving projects'
            data={[
              {
                name: 'yes',
                count: data.userProfilesOverview.count_allow_project_receiving,
              },
              {
                name: 'no',
                count: data.userProfilesOverview.count_active - data.userProfilesOverview.count_allow_project_receiving,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoPositive'
          />
          <CustomPie
            title='Auto-adds sharers'
            data={[
              {
                name: 'yes',
                count: data.userProfilesOverview.count_project_receive_auto_add,
              },
              {
                name: 'no',
                count: data.userProfilesOverview.count_active - data.userProfilesOverview.count_project_receive_auto_add,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoPositive'
          />
          <CustomPie
            title='Allows sharing projects'
            data={[
              {
                name: 'yes',
                count: data.userProfilesOverview.count_allow_project_sharing,
              },
              {
                name: 'no',
                count: data.userProfilesOverview.count_active - data.userProfilesOverview.count_allow_project_sharing,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoPositive'
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Project sharing'
            rows={projectSharingRows}
          />
          <CustomBarChartInline
            label='Project shares per profile'
            data={data.projectSharingUsageGroups}
            dataKey='group'
            xAxisTickFormatter={(v) => v === 1 ? '<10' : v}
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.userProfilesOverview.count_all) + ` - ${v} profile(s)`}
            tooltipLabelFormatter={(v) => {
              if (v === 1) {
                return 'Less than 10 projects shared';
              } else if (v)
                return v + ' projects shared';
              else {
                return 'No projects shared';
              }
            }}
            total={data.userProfilesOverview.count_all}
          />
          <CustomPie
            title='Project shares accepted'
            data={[
              {
                name: 'yes',
                count: data.projectSharingAcceptance.count_accepted,
              },
              {
                name: 'no',
                count: data.projectSharingAcceptance.count_not_accepted,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoPositive'
          />
          <CustomPie
            title='Project sharers blocked'
            data={[
              {
                name: 'yes',
                count: data.projectSharingBlocks.count_blocked,
              },
              {
                name: 'no',
                count: data.projectSharingAcceptance.count_all - data.projectSharingBlocks.count_blocked,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoNegative'
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Project sync'
            rows={projectUploadRows}
          />
          <CustomBarChartInline
            label='Projects synced per profile'
            data={data.projectUploadUsageGroups}
            dataKey='group'
            xAxisTickFormatter={(v) => v === 1 ? '<10' : v}
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.projectUploadUsageGroups[0].total) + ` - ${v} profile(s)`}
            tooltipLabelFormatter={(v) => {
              if (v === 1) {
                return 'Less than 10 projects uploaded';
              } else if (v)
                return v + ' projects uploaded';
              else {
                return 'No projects uploaded';
              }
            }}
            total={data.projectUploadUsageGroups[0].total}
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Canvas clicks'
            rows={[]}
          />
          <CustomBarChartInline
            label='Connect mode'
            data={data.connectModeOpenGroups}
            dataKey='group'
            xAxisTickFormatter={(v) => v === 1 ? '<10' : v}
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.userProfilesOverview.count_all) + ` - ${v} profile(s)`}
            tooltipLabelFormatter={(v) => {
              if (v === 1) {
                return 'Less than 10 clicks';
              } else if (v)
                return v + ' clicks';
              else {
                return 'No clicks';
              }
            }}
            total={data.userProfilesOverview.count_all}
          />
          <CustomBarChartInline
            label='Delete mode'
            data={data.deleteModeOpenGroups}
            dataKey='group'
            xAxisTickFormatter={(v) => v === 1 ? '<10' : v}
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.userProfilesOverview.count_all) + ` - ${v} profile(s)`}
            tooltipLabelFormatter={(v) => {
              if (v === 1) {
                return 'Less than 10 clicks';
              } else if (v)
                return v + ' clicks';
              else {
                return 'No clicks';
              }
            }}
            total={data.userProfilesOverview.count_all}
          />
          <CustomBarChartInline
            label='Project help'
            data={data.helpProjectOpenGroups}
            dataKey='group'
            xAxisTickFormatter={(v) => v === 1 ? '<10' : v}
            tooltipValueFormatter={(v) =>
              formatters.percentage(v / data.userProfilesOverview.count_all) + ` - ${v} profile(s)`}
            tooltipLabelFormatter={(v) => {
              if (v === 1) {
                return 'Less than 10 clicks';
              } else if (v)
                return v + ' clicks';
              else {
                return 'No clicks';
              }
            }}
            total={data.userProfilesOverview.count_all}
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Connection'
            rows={connectionRows}
          />
          <CustomPie
            title='FW updates'
            data={[
              {
                name: 'successful',
                count: data.statsOverview.firmware_update_success_count,
              },
              {
                name: 'failed',
                count: data.statsOverview.firmware_update_failed_count,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='fwUpdateResult'
          />
          <CustomPie
            title='BLE connection issues'
            data={[
              {
                name: 'yes',
                count: data.statsOverview.ble_connection_issues_count,
              },
              {
                name: 'no',
                count: data.statsOverview.no_ble_connection_issues_count,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='yesNoNegative'
          />
        </div>
      </div>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            title='Sessions'
            rows={sessionRows}
          />
          <CustomPie
            title='Time per app'
            data={data.appUsage}
            dataKey='duration'
            nameKey='app'
            enumName='app'
          />
          <CustomPie
            title='Time per OS'
            data={data.osUsage}
            dataKey='duration'
            nameKey='os'
            enumName='os'
          />
        </div>
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Projects'
          rows={projectRows}
        />
        <CustomPie
          title='Total time per project type'
          data={[
            {
              duration: data.statsOverview.project_open_custom_duration,
              type: 'custom',
            },
            {
              duration: data.statsOverview.project_open_premade_duration,
              type: 'premade',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectType'
        />
        <CustomPie
          title='Build time per project type'
          data={[
            {
              duration: data.statsOverview.project_build_custom_duration,
              type: 'custom',
            },
            {
              duration: data.statsOverview.project_build_premade_duration,
              type: 'premade',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectType'
        />
        <CustomPie
          title='Play time per project type'
          data={[
            {
              duration: data.statsOverview.project_play_custom_duration,
              type: 'custom',
            },
            {
              duration: data.statsOverview.project_play_premade_duration,
              type: 'premade',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectType'
        />
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Help usage'
          rows={helpUsageRows}
        />
        <CustomPie
          title='Time per help type'
          data={[
            {
              duration: data.statsOverview.help_open_main_menu_duration,
              type: 'main_menu',
            },
            {
              duration: data.statsOverview.help_open_charging_duration,
              type: 'charging',
            },
            {
              duration: data.statsOverview.help_open_firmware_update_duration,
              type: 'firmware_update',
            },
            {
              duration: data.statsOverview.help_open_project_duration,
              type: 'project',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='helpType'
        />
        <CustomPie
          title='Avg. time per help type'
          data={[
            {
              duration: data.statsOverview.help_open_main_menu_duration_avg,
              type: 'main_menu',
            },
            {
              duration: data.statsOverview.help_open_charging_duration_avg,
              type: 'charging',
            },
            {
              duration: data.statsOverview.help_open_firmware_update_duration_avg,
              type: 'firmware_update',
            },
            {
              duration: data.statsOverview.help_open_project_duration_avg,
              type: 'project',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='helpType'
        />
      </div>
      <h2 className='chart-title'>Block usage</h2>
      <div className='charts-container'>
        <CustomPie
          title='Physical blocks connected'
          data={data.userPhysicalModulesUsage}
          dataKey='count'
          nameKey='module'
          enumName='modules'
          width={550}
          height={320}
          outerRadius={150}
        />
        <CustomPie
          title='Blocks used in projects'
          data={data.userProjectModulesUsage}
          dataKey='count'
          nameKey='module'
          enumName='modules'
          width={550}
          height={320}
          outerRadius={150}
        />
      </div>
    </>
  );
};

export default Overview;
