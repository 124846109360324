import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import CustomPie from '../../components/CustomPie';
import TableInfo from '../../components/TableInfo';

const Overview = () => {
  const { isLoading, data } = useApi('analytics/marketing/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const rows = [
    {
      label: 'Clicks',
      value: data.statsOverview.count_clicks,
    },
    {
      label: 'Downloads',
      value: data.statsOverview.count_converted,
    },
    {
      label: 'Conversion percentage',
      value: formatters.percentage(data.statsOverview.count_converted / data.statsOverview.count_clicks),
    },
    {
      label: 'Campaigns',
      value: data.campaignsChannelsOverview.count_campaigns,
    },
    {
      label: 'Channels',
      value: data.campaignsChannelsOverview.count_channels,
    },
  ];

  return (
    <>
      <h1>Global overview</h1>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            rows={rows}
          />
          <CustomPie
            title='Conversions'
            data={[
              {
                name: 'converted',
                count: data.statsOverview.count_converted,
              },
              {
                name: 'unconverted',
                count: data.statsOverview.count_clicks - data.statsOverview.count_converted,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='clickConversion'
          />
          <CustomPie
            title='Clicks per OS'
            data={[
              {
                name: 'android',
                count: data.statsOverview.count_clicks_android,
              },
              {
                name: 'ios',
                count: data.statsOverview.count_clicks_android,
              },
              {
                name: 'windows',
                count: data.statsOverview.count_clicks_windows,
              },
              {
                name: 'macos',
                count: data.statsOverview.count_clicks_macos,
              },
              {
                name: 'unknown',
                count: data.statsOverview.count_clicks_unknown,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='osMarketing'
          />
          <CustomPie
            title='Conversions per OS'
            data={[
              {
                name: 'android',
                count: data.statsOverview.count_converted_android,
              },
              {
                name: 'ios',
                count: data.statsOverview.count_converted_ios,
              },
              {
                name: 'windows',
                count: data.statsOverview.count_converted_windows,
              },
              {
                name: 'macos',
                count: data.statsOverview.count_converted_macos,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='osMarketing'
          />
        </div>
      </div>
    </>
  );
};

export default Overview;
