import {
  Link,
  Route,
  Switch,
  useRouteMatch,
} from 'react-router-dom';

import DefaultFilters from '../../../components/DefaultFilters';

import Overview from './Overview';
import Events from './Events';

const Session = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <h1>Single session statistics</h1>
      <div className='navigation-container'>
        <Link to={`${url}`} className='navigation-item'>Overview</Link>
        <Link to={`${url}/events`} className='navigation-item'>Events</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/`}>
          <Overview />
        </Route>
        <Route exact path={`${path}/events`}>
          <DefaultFilters component={<Events />} type='events'/>
        </Route>
      </Switch>
    </>
  );
};

export default Session;
