import { map } from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import enums from '../helpers/enums';
import formatters from '../helpers/formatters';

const CustomBarChart = (props) => {
  const {
    label,
    data,
    enumName,
    onBarClick,
    dataKey = 'date',
    xAxisTickFormatter = formatters.shortDate,
    yAxisTickFormatter,
    tooltipLabelFormatter = formatters.date,
    tooltipValueFormatter = (v) => v,
  } = props;

  const bars = map(enums[enumName], (e, dataKey) => ({ ...e, dataKey }));
  const getLabelByKey = (value) => bars.find((b) => b.dataKey === value).label;

  // TODO: this was nicer but not perfect
  // const maxValueObject = maxBy(data, 'total');
  // const yAxisTicks = range(3600, maxValueObject ? maxValueObject.total : 10800 + 3600, 3600);

  return (
    <div className='chart-container full-width'>
      <h3>{label}</h3>
      <ResponsiveContainer width='100%' height={400}>
        <BarChart
          data={data}
        >
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis
            dataKey={dataKey}
            tickFormatter={xAxisTickFormatter}
          />
          <YAxis
            width={140}
            minTickGap={50}
            tickFormatter={yAxisTickFormatter}
          />
          <Tooltip
            formatter={(value, name) => [
              tooltipValueFormatter(value),
              getLabelByKey(name),
            ]}
            labelFormatter={(label, [value]) =>
              `${tooltipLabelFormatter(label)}: ${(value && tooltipValueFormatter(value.payload.total)) || 0}`
            }
          />
          <Legend formatter={getLabelByKey}/>
          {bars.map((bar) => (
            <Bar
              key={bar.dataKey}
              dataKey={bar.dataKey}
              stackId='a'
              fill={bar.color}
              isAnimationActive={false}
              onClick={onBarClick}
              className={onBarClick ? 'link' : ''}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomBarChart;
