import i18n from '../../helpers/i18n';

import ErrorIcon from '../../icons/ErrorIcon';

const AccountDeleted = ({ lang }) => {
  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__title-container'>
          <ErrorIcon />
          <div className='main-banner__title'>
            {i18n.get('acceptProject.errorAccountDeletedTitle', lang)}
          </div>
          <div className='main-banner__subtitle'>
            {i18n.get('acceptProject.errorAccountDeletedSubtitle', lang)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountDeleted;
