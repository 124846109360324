import {
  Link,
  Route,
  Switch,
} from 'react-router-dom';

import DefaultFilters from '../../components/DefaultFilters';
import Campaigns from './Campaigns';
import Campaign from './Campaign';
import Channels from './Channels';
import Channel from './Channel';
import Info from './Info';
import Overview from './Overview';
import Timeline from './Timeline';

const MarketingAnalytics = () => {
  return (
    <>
      <div className='navigation-container'>
        <Link to='/analytics/marketing' className='navigation-item'>Overview</Link>
        <Link to='/analytics/marketing/timeline' className='navigation-item'>Timeline</Link>
        <Link to='/analytics/marketing/campaigns' className='navigation-item'>Campaigns</Link>
        <Link to='/analytics/marketing/channels' className='navigation-item'>Channels</Link>
        <Link to='/analytics/marketing/info' className='navigation-item'>Info</Link>
      </div>

      <div className='content-container'>
        <Switch>
          <Route exact path='/analytics/marketing'>
            <Overview />
          </Route>
          <Route path='/analytics/marketing/timeline'>
            <Timeline />
          </Route>
          <Route exact path='/analytics/marketing/campaigns'>
            <DefaultFilters component={<Campaigns />} type='campaigns'/>
          </Route>
          <Route path='/analytics/marketing/campaigns/:campaignName'>
            <Campaign />
          </Route>
          <Route exact path='/analytics/marketing/channels'>
            <DefaultFilters component={<Channels />} type='channels'/>
          </Route>
          <Route path='/analytics/marketing/channels/:channelName'>
            <Channel />
          </Route>
          <Route exact path='/analytics/marketing/info'>
            <Info />
          </Route>
        </Switch>
      </div>
    </>
  );
};

export default MarketingAnalytics;
