import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Factories = () => {
  const { isLoading, data } = useApi('analytics/production/factories');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.factories}
      total={data.total}
    />
  );
};

export default Factories;

const columns = [
  {
    key: 'id',
    label: 'ID',
    isOrderable: true,
  },
  {
    key: 'factory_id',
    label: 'Name',
    formatter: (id) => enums.factory[id].label,
  },
  {
    key: 'count_tests',
    label: 'Tests',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_tests_passed',
    label: 'Passed',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_tests_failed',
    label: 'Failed',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_jigs',
    label: 'Jigs',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_modules',
    label: 'Modules',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'avg_tests_per_module',
    label: 'Avg. tests per test module',
    isOrderable: true,
    formatter: formatters.float,
    defaultOrderDir: 'DESC',
  },
];
