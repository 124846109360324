import enums from '../../helpers/enums';
import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const PhysicalModules = () => {
  const { isLoading, data } = useApi('analytics/production/physical-modules');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.modules}
      total={data.total}
      filterOpts={{
        filters: [
          {
            name: 'factories',
            type: 'enum',
            enumName: 'factory',
          },
        ]
      }}
    />
  );
};

export default PhysicalModules;

const columns = [
  {
    key: 'id',
    label: 'Number',
    isOrderable: true,
  },
  {
    key: 'module_num',
    label: 'Name',
    formatter: (moduleNum) => enums.module[moduleNum].label,
  },
  {
    key: 'count_tests',
    label: 'Tests',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_tests_passed',
    label: 'Passed',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_tests_failed',
    label: 'Failed',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'avg_data_sample',
    label: 'Avg. data sample',
    formatter: formatters.float,
  },
  {
    key: 'count_factories',
    label: 'Factories',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_jigs',
    label: 'Jigs',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_modules',
    label: 'Modules',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'avg_tests_per_module',
    label: 'Avg. tests per test module',
    isOrderable: true,
    formatter: formatters.float,
    defaultOrderDir: 'DESC',
  },
];
