import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Weekly = () => {
  const { isLoading, data } = useApi('analytics/production/timeline/weekly');

  return (
    <div className='stats-main-container'>
      <DateFilters selectWeeks/>
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          {data.testsPerFactoryWeekly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per factory, total tests: ${data.testsPerFactoryWeekly.total || 0}`}
                  data={data.testsPerFactoryWeekly.weekly}
                  enumName='factory'
                  dataKey='week_start'
                  xAxisTickFormatter={formatters.weekShort}
                  tooltipLabelFormatter={formatters.week}
                />
              </div>
            ) : null
          }
          {data.testsPassedVsFailedWeekly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Passed vs. failed tests, total tests: ${data.testsPassedVsFailedWeekly.total || 0}`}
                  data={data.testsPassedVsFailedWeekly.weekly}
                  enumName='testResult'
                  dataKey='week_start'
                  xAxisTickFormatter={formatters.weekShort}
                  tooltipLabelFormatter={formatters.week}
                />
              </div>
            ) : null
          }
          {data.testsPerModuleWeekly
            ? (
              <div className='charts-container'>
                <CustomBarChart
                  label={`Tests per module, total tests: ${data.testsPerModuleWeekly.total || 0}`}
                  data={data.testsPerModuleWeekly.weekly}
                  enumName='module'
                  dataKey='week_start'
                  xAxisTickFormatter={formatters.weekShort}
                  tooltipLabelFormatter={formatters.week}
                />
              </div>
            ) : null
          }
        </>
      )}
    </div>
  )
};

export default Weekly;
