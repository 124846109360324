import { useContext, useEffect } from 'react';
import * as dateFns from 'date-fns';
import { useHistory } from 'react-router-dom';
import querystring from 'querystring';

import { BatchContext } from '../context/batchContext';

export const BatchHelper = () => {
  const { batch, setBatch, batches } = useContext(BatchContext);

  const history = useHistory();

  useEffect(() => {
    setBatch(batch);
  }, [batch]);

  const changeBatch = (batch) => {
    setBatch(batch);
    const query = {};

    if (batch.dateStart) {
      query.dateStart = formatDate(batch.dateStart);
    }

    if (batch.dateEnd) {
      query.dateEnd = formatDate(batch.dateEnd);
    }

    history.push({
      pathname: history.pathname,
      search: querystring.stringify(query)
    });
  };

  return (
    <div className='navigation-container'>
      {
        Object.keys(batches).map((item, i) => (
          <span key={i}
                className='navigation-item'
                onClick={() => {
                  changeBatch(batches[item]);
                }}>

              {batches[item].slug === batch.slug ? <b>{batches[item].name}</b> : batches[item].name}

            <span> {formatDateRange(batches[item].dateStart, batches[item].dateEnd)} </span>

            </span>
        ))
      }
    </div>
  );
};

export const formatDateRange = (dateStart, dateEnd) => {
  if (!dateStart && !dateEnd) {
    return '';
  }
  return `${formatDate(dateStart)} - ${formatDate(dateEnd)}`;
};

export const formatDate = (date) => {
  if (!date) {
    return '';
  }
  return dateFns.format(new Date(date), 'yyyy-MM-dd');
};
