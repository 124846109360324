import {
  Link,
  Route,
  Switch
} from 'react-router-dom';

import DefaultFilters from '../../components/DefaultFilters';

import Events from './Events';
import Factories from './Factories';
import Factory from './Factory';
import Jig from './Jig';
import Jigs from './Jigs';
import Module from './Module';
import Modules from './Modules';
import PhysicalModule from './PhysicalModule';
import PhysicalModules from './PhysicalModules';
import Overview from './Overview';
import Timeline from './Timeline';

import { BatchContext } from '../../context/batchContext';
import { formatDate } from '../../components/BatchHelper';

import { useEffect, useState } from 'react';
import useApi from '../../helpers/useApi';

const defaultBatches = {
  allBatches: {
    id: 'allBatches',
    slug: 'allBatches',
    name: 'All',
    dateStart: null,
    dateEnd: null
  }
};

const ProductionAnalytics = () => {
  const [batch, setBatch] = useState(defaultBatches.allBatches);

  const { data } = useApi('analytics/production/batches');

  const [batches, setBatches] = useState({
    ...defaultBatches
  });

  useEffect(() => {
    const defaultBatch = batches.allBatches;

    const newBatches = {
      'allBatches': defaultBatch
    };

    if (data.success && data.batches && data.batches.length > 0) {
      data.batches.forEach((batch) => {
          newBatches[batch.slug] = {
            id: batch.id,
            slug: batch.slug,
            name: batch.name,
            dateStart: formatDate(batch.start_date) || null,
            dateEnd: formatDate(batch.end_date) || null
          };
        }
      );
    }

    // console.log('Currently we have batches', batches);
    // console.log('And these batches were received', data);
    // console.log('We will persist', newBatches);

    setBatches(newBatches);
  }, [data]);

  return (
    <>
      <div className='navigation-container'>
        <Link to='/analytics/production' className='navigation-item'>Overview</Link>
        <Link to='/analytics/production/timeline' className='navigation-item'>Timeline</Link>
        <Link to='/analytics/production/factories' className='navigation-item'>Factories</Link>
        <Link to='/analytics/production/physical-modules' className='navigation-item'>Modules</Link>
        <Link to='/analytics/production/jigs' className='navigation-item'>Jigs</Link>
        <Link to='/analytics/production/modules' className='navigation-item'>Test Modules</Link>
        <Link to='/analytics/production/events' className='navigation-item'>Events</Link>
      </div>

      <div className='content-container'>
        <BatchContext.Provider value={{ batch, setBatch, setBatches, batches }}>
          <Switch>
            <Route exact path='/analytics/production'>
              <Overview />
            </Route>
            <Route path='/analytics/production/timeline'>
              <Timeline />
            </Route>
            <Route exact path='/analytics/production/factories'>
              <DefaultFilters component={<Factories />} type='factories' />
            </Route>
            <Route path='/analytics/production/factories/:factoryId'>
              <Factory />
            </Route>
            <Route exact path='/analytics/production/physical-modules'>
              <DefaultFilters component={<PhysicalModules />} type='physicalModules' />
            </Route>
            <Route path='/analytics/production/physical-modules/:moduleNum'>
              <PhysicalModule />
            </Route>
            <Route exact path='/analytics/production/jigs'>
              <DefaultFilters component={<Jigs />} type='jigs' />
            </Route>
            <Route path='/analytics/production/jigs/:jigId'>
              <Jig />
            </Route>
            <Route exact path='/analytics/production/modules'>
              <DefaultFilters component={<Modules />} type='modules' />
            </Route>
            <Route path='/analytics/production/modules/:moduleId'>
              <Module />
            </Route>
            <Route exact path='/analytics/production/events'>
              <DefaultFilters component={<Events />} type='productionEvents' />
            </Route>
          </Switch>
        </BatchContext.Provider>
      </div>
    </>
  );
};

export default ProductionAnalytics;
