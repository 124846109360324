const RoboLogoIcon = () => {
  return (
      <svg
        width='84'
        height='84'
        viewBox='0 0 84 84'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect
          width='84'
          height='84'
          rx='20'
          fill='#7E63B6'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M55.8213 39.5366H17.3199C15.9768 39.5366 14.9023 38.4377 14.9023 37.0641V17.3757C14.9023 16.0021 15.9768 14.9032 17.3199 14.9032H55.8213C62.4919 14.9032 67.8642 20.4434 67.8642 27.2199C67.8642 34.0422 62.4472 39.5366 55.8213 39.5366ZM60.6113 27.174C60.6113 24.4725 58.4624 22.2748 55.821 22.2748C53.1796 22.2748 51.0307 24.4725 50.986 27.174C50.986 29.8754 53.1796 32.0732 55.821 32.0732C58.4624 32.0732 60.6113 29.8754 60.6113 27.174ZM41.4059 32.1653C44.092 32.1653 46.2409 29.9217 46.2409 27.2203H36.6156C36.6156 29.9675 38.7645 32.1653 41.4059 32.1653ZM31.7354 27.174C31.7354 24.4725 29.5865 22.2748 26.9451 22.2748C24.3037 22.2748 22.1548 24.4725 22.1101 27.174C22.1101 29.8754 24.3037 32.0732 26.9451 32.0732C29.5865 32.0732 31.7354 29.8754 31.7354 27.174Z'
          fill='white'
        />
        <path
          d='M55.5475 42C48.0867 42 41.9991 48.0418 41.9991 55.5484C41.9991 63.0091 48.041 69.0968 55.5475 69.0968C63.0083 69.0968 69.0959 63.0549 69.0959 55.5484C69.0959 48.0876 63.0083 42 55.5475 42Z'
          fill='white'
        />
        <path
          d='M37.0633 43.2313H17.3748C16.0012 43.2313 14.9023 44.3302 14.9023 45.7038V65.3922C14.9023 66.7658 16.0012 67.8647 17.3748 67.8647H37.0633C38.4369 67.8647 39.5358 66.7658 39.5358 65.3922V45.7038C39.5358 44.3302 38.4369 43.2313 37.0633 43.2313Z'
          fill='white'
        />
      </svg>
  );
};

export default RoboLogoIcon;
