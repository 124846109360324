import i18n from '../../helpers/i18n';

import ErrorIcon from '../../icons/ErrorIcon';
import RoboLogoIcon from '../../icons/RoboLogoIcon';
import StepIcon from '../../icons/StepIcon';

const LinkExpired = ({ lang }) => {
  return (
    <div className='main-banner-container'>
      <div className='main-banner'>
        <div className='main-banner__title-container'>
          <ErrorIcon />
          <div className='main-banner__title'>
            {i18n.get('verifyEmail.errorExpiredTitle', lang)}
          </div>
        </div>
        <div className='project-accepted-tutorial'>
          <div className='unblock-user-tutorial__step-container'>
            <div className='unblock-user-tutorial__step-description'>
              <StepIcon stepNumber='1' />
              <div>
                {i18n.get('verifyEmail.errorExpiredStep1', lang)}
              </div>
            </div>
            <div className='project-accepted-tutorial__app-open'>
              <RoboLogoIcon />
              <div className='project-accepted-tutorial__app-open__app-name'>
                Robo Wunderkind
              </div>
            </div>
          </div>
          <div className='unblock-user-tutorial__step-container'>
            <div className='unblock-user-tutorial__step-description'>
              <StepIcon stepNumber='2' />
              <div>
                {i18n.get('verifyEmail.errorExpiredStep2', lang)}
              </div>
            </div>
            <div className='unblock-user-preview__container'>
              <div className='unblock-user-preview__parental-control'>
                {i18n.get('verifyEmail.errorExpiredSettings', lang)}
              </div>
              <div className='unblock-user-preview__greyed-out-option'/>
              <div className='unblock-user-preview__unblock'>
                {i18n.get('verifyEmail.errorExpiredResendEmail', lang)}
              </div>
              <div className='unblock-user-preview__greyed-out-option'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkExpired;
