import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import Table from '../../components/Table';

const Campaigns = () => {
  const { isLoading, data } = useApi('analytics/marketing/campaigns');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  return (
    <Table
      columns={columns}
      data={data.campaigns}
      total={data.total}
      filterOpts={{
        filters: [
          {
            name: 'channelName',
            type: 'text',
            placeholder: 'Filter by channel',
          },
        ]
      }}
    />
  );
};

export default Campaigns;

const columns = [
  {
    key: 'campaign',
    label: 'Name',
    isOrderable: true,
  },
  {
    key: 'count_clicks',
    label: 'Clicks',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'count_converted',
    label: 'Downloads',
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
  {
    key: 'created_at',
    label: 'Started',
    formatter: formatters.dateTime,
    isOrderable: true,
    defaultOrderDir: 'DESC',
  },
];
