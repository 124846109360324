import querystring from 'querystring';
import { useHistory, useRouteMatch } from 'react-router-dom';

import enums from '../../../helpers/enums';
import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomPie from '../../../components/CustomPie';
import TableInfo from '../../../components/TableInfo';

const Overview = () => {
  const history = useHistory();
  const { url } = useRouteMatch();

  const { isLoading, data } = useApi('analytics/apps/user-profile/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const mainInfoRows = [
    {
      label: 'User email',
      value: data.userProfile.user_email,
      onClick: () => history.push(`/analytics/apps/users/${data.userProfile.user_id}`),
    },
    {
      label: 'Username',
      value: data.userProfile.username,
    },
    {
      label: 'Status',
      value: enums.userProfileStatus[data.userProfile.status].label,
    },
    {
      label: 'Age',
      value: data.userProfile.age,
    },
    {
      label: 'Age group',
      value: enums.userProfileAgeGroup[data.userProfile.age_group].label,
    },
    {
      label: 'Created at',
      value: formatters.date(data.userProfile.created_at),
    },
  ].filter(({ value }) => value != null);

  const connectionRows = [
    {
      label: 'Avg. main block conn. time',
      value: formatters.duration(data.userProfile.system_cube_duration_avg),
    },
    {
      label: 'Successful FW updates',
      value: data.userProfile.firmware_update_success_count,
    },
    {
      label: 'Failed FW updates',
      value: data.userProfile.firmware_update_failed_count,
    },
    {
      label: 'Avg. FW update time',
      value: formatters.duration(data.userProfile.firmware_update_success_duration_avg),
    },
    {
      label: 'FW update failed',
      value: formatters.booleanYesNo(data.userProfile.has_fw_update_failed),
    },
    {
      label: 'BLE conn. issues',
      value: formatters.booleanYesNo(data.userProfile.has_ble_connection_issues),
    },
  ];

  const projectSharingRows = [
    {
      label: 'Project shares',
      value: data.userProfile.project_share_count,
    },
    {
      label: 'Unique recipients',
      value: data.userProfile.project_share_unique_recipients_count,
    },
    {
      label: 'Allows receiving project',
      value: formatters.booleanYesNo(data.userProfile.allow_project_receiving),
    },
    {
      label: 'Auto-adds sharers',
      value: formatters.booleanYesNo(data.userProfile.project_receive_auto_add),
    },
    {
      label: 'Allows sharing project',
      value: formatters.booleanYesNo(data.userProfile.allow_project_sharing),
    },
  ];

  const sessionRows = [
    {
      label: 'Latest session',
      value: formatters.dateTime(data.userProfile.latest_session_created_at),
    },
    {
      label: 'Sessions',
      value: data.userProfile.session_count,
    },
    {
      label: 'Total time',
      value: formatters.duration(data.userProfile.session_duration),
    },
    {
      label: 'Average session length',
      value: formatters.duration(data.userProfile.session_duration_avg),
    },
    {
      label: 'Sessions in last 10 days',
      value: data.userProfile.session_count_last_10_days,
    },
    {
      label: 'Time in last 10 days',
      value: formatters.duration(data.userProfile.session_duration_last_10_days),
    },
  ].filter(({ value }) => value != null);

  const projectRows = [
    {
      label: 'Total time',
      value: formatters.secondsToDuration(data.userProfile.project_open_duration),
    },
    {
      label: 'Build time',
      value: formatters.secondsToDuration(data.userProfile.project_build_duration),
    },
    {
      label: 'Play time',
      value: formatters.secondsToDuration(data.userProfile.project_play_duration),
    },
    {
      label: 'Custom projects created',
      value: data.userProfile.projects_created_count,
      onClick: () => {
        history.push({
          pathname: `${url}/projects/`,
          search: querystring.stringify({ projectTypes: ['custom'] }),
        });
      },
    },
  ];

  const helpUsageRows = [
    {
      label: 'Time (main menu)',
      value: formatters.secondsToDuration(data.userProfile.help_open_main_menu_duration),
    },
    {
      label: 'Time (charging)',
      value: formatters.secondsToDuration(data.userProfile.help_open_charging_duration),
    },
    {
      label: 'Time (FW update)',
      value: formatters.secondsToDuration(data.userProfile.help_open_firmware_update_duration),
    },
    {
      label: 'Time (projects)',
      value: formatters.secondsToDuration(data.userProfile.help_open_project_duration),
    },
    {
      label: 'Avg. time (main menu)',
      value: formatters.secondsToDuration(data.userProfile.help_open_main_menu_duration_avg),
    },
    {
      label: 'Avg. time (charging)',
      value: formatters.secondsToDuration(data.userProfile.help_open_charging_duration_avg),
    },
    {
      label: 'Avg. time (FW update)',
      value: formatters.secondsToDuration(data.userProfile.help_open_firmware_update_duration_avg),
    },
    {
      label: 'Avg. time (projects)',
      value: formatters.secondsToDuration(data.userProfile.help_open_project_duration_avg),
    },
  ];

  return (
    <div className='stats-main-container'>
      <div className='charts-container'>
        <TableInfo rows={mainInfoRows}/>
        <TableInfo rows={connectionRows}/>
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Project sharing'
          rows={projectSharingRows}
        />
        <CustomPie
          title='Project shares accepted'
          data={[
            {
              name: 'yes',
              count: data.userProfile.project_share_recipient_count - data.userProfile.project_share_recipient_not_accepted_count,
            },
            {
              count: data.userProfile.project_share_recipient_not_accepted_count,
              name: 'no',
            },
          ]}
          dataKey='count'
          nameKey='name'
          enumName='yesNoPositive'
        />
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Project sync'
          rows={[]}
        />
        <CustomPie
          title='Project sync usage'
          data={[
            {
              count: data.userProfile.project_upload_count,
              type: 'used',
            },
            {
              count: data.userProfile.project_upload_limit - data.userProfile.project_upload_count,
              type: 'unused',
            },
          ]}
          dataKey='count'
          nameKey='type'
          enumName='projectUploadUsage'
        />
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Sessions'
          rows={sessionRows}
        />
        <CustomPie
          title='Time per app'
          data={data.appUsage}
          dataKey='duration'
          nameKey='app'
          enumName='app'
        />
        <CustomPie
          title='Time per OS'
          data={data.osUsage}
          dataKey='duration'
          nameKey='os'
          enumName='os'
        />
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Projects'
          rows={projectRows}
        />
        <CustomPie
          title='Total time per project type'
          data={[
            {
              duration: data.userProfile.project_open_custom_duration,
              type: 'custom',
            },
            {
              duration: data.userProfile.project_open_premade_duration,
              type: 'premade',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectType'
        />
        <CustomPie
          title='Build time per project type'
          data={[
            {
              duration: data.userProfile.project_build_custom_duration,
              type: 'custom',
            },
            {
              duration: data.userProfile.project_build_premade_duration,
              type: 'premade',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectType'
        />
        <CustomPie
          title='Play time per project type'
          data={[
            {
              duration: data.userProfile.project_play_custom_duration,
              type: 'custom',
            },
            {
              duration: data.userProfile.project_play_premade_duration,
              type: 'premade',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='projectType'
        />
      </div>
      <div className='charts-container'>
        <TableInfo
          title='Help usage'
          rows={helpUsageRows}
        />
        <CustomPie
          title='Time per help type'
          data={[
            {
              duration: data.userProfile.help_open_main_menu_duration,
              type: 'main_menu',
            },
            {
              duration: data.userProfile.help_open_charging_duration,
              type: 'charging',
            },
            {
              duration: data.userProfile.help_open_firmware_update_duration,
              type: 'firmware_update',
            },
            {
              duration: data.userProfile.help_open_project_duration,
              type: 'project',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='helpType'
        />
        <CustomPie
          title='Avg. time per help type'
          data={[
            {
              duration: data.userProfile.help_open_main_menu_duration_avg,
              type: 'main_menu',
            },
            {
              duration: data.userProfile.help_open_charging_duration_avg,
              type: 'charging',
            },
            {
              duration: data.userProfile.help_open_firmware_update_duration_avg,
              type: 'firmware_update',
            },
            {
              duration: data.userProfile.help_open_project_duration_avg,
              type: 'project',
            },
          ].filter(({ duration }) => !!duration)}
          dataKey='duration'
          nameKey='type'
          enumName='helpType'
        />
      </div>
      <h2 className='chart-title'>Block usage</h2>
      <div className='charts-container'>
        <CustomPie
          title='Physical blocks connected'
          data={data.userProfilePhysicalModulesUsage}
          dataKey='count'
          nameKey='module'
          enumName='modules'
          width={530}
          height={320}
          outerRadius={150}
        />
        <CustomPie
          title='Blocks used in projects'
          data={data.userProfileProjectModulesUsage}
          dataKey='count'
          nameKey='module'
          enumName='modules'
          width={530}
          height={320}
          outerRadius={150}
        />
      </div>
    </div>
  );
};

export default Overview;
