import querystring from 'querystring';
import {
  Link,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';

import formatters from '../../helpers/formatters';
import useApi from '../../helpers/useApi';

import CustomPie from '../../components/CustomPie';
import TableInfo from '../../components/TableInfo';

import Timeline from './Timeline';

const Campaign = () => {
  const { path, url } = useRouteMatch();

  return (
    <>
      <h1>Single campaign statistics</h1>
      <div className='navigation-container'>
        <Link to={`${url}`} className='navigation-item'>Overview</Link>
        <Link to={`${url}/timeline`} className='navigation-item'>Timeline</Link>
      </div>
      <Switch>
        <Route exact path={`${path}/`}>
          <Overview />
        </Route>
        <Route path={`${path}/timeline`}>
          <Timeline />
        </Route>
      </Switch>
    </>
  );
};

export default Campaign;

const Overview = () => {
  const history = useHistory();

  const { isLoading, data } = useApi('analytics/marketing/campaign/overview');

  if (isLoading) {
    return (
      <div>Loading....</div>
    );
  }

  const rows = [
    {
      label: 'Campaign name',
      value: data.campaignStats.campaign,
    },
    {
      label: 'Clicks',
      value: data.campaignStats.count_clicks,
    },
    {
      label: 'Downloads',
      value: data.campaignStats.count_converted,
    },
    {
      label: 'Conversion percentage',
      value: formatters.percentage(data.campaignStats.count_converted / data.campaignStats.count_clicks),
    },
    {
      label: 'Channels',
      value: data.campaignStats.count_channels,
      onClick: () => history.push({
        pathname: '/analytics/marketing/channels',
        search: querystring.stringify({ campaignName: data.campaignStats.campaign }),
      }),
    },
  ];

  return (
    <>
      <div className='stats-main-container'>
        <div className='charts-container'>
          <TableInfo
            rows={rows}
          />
          <CustomPie
            title='Conversions'
            data={[
              {
                name: 'converted',
                count: data.campaignStats.count_converted,
              },
              {
                name: 'unconverted',
                count: data.campaignStats.count_clicks - data.campaignStats.count_converted,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='clickConversion'
          />
          <CustomPie
            title='Clicks per OS'
            data={[
              {
                name: 'android',
                count: data.campaignStats.count_clicks_android,
              },
              {
                name: 'ios',
                count: data.campaignStats.count_clicks_android,
              },
              {
                name: 'windows',
                count: data.campaignStats.count_clicks_windows,
              },
              {
                name: 'macos',
                count: data.campaignStats.count_clicks_macos,
              },
              {
                name: 'unknown',
                count: data.campaignStats.count_clicks_unknown,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='osMarketing'
          />
          <CustomPie
            title='Conversions per OS'
            data={[
              {
                name: 'android',
                count: data.campaignStats.count_converted_android,
              },
              {
                name: 'ios',
                count: data.campaignStats.count_converted_ios,
              },
              {
                name: 'windows',
                count: data.campaignStats.count_converted_windows,
              },
              {
                name: 'macos',
                count: data.campaignStats.count_converted_macos,
              },
            ]}
            dataKey='count'
            nameKey='name'
            enumName='osMarketing'
          />
        </div>
      </div>
    </>
  );
};
