import './TableInfo.css';

const TableInfo = ({ title, rows }) => {
  return (
    <div className='table-info-container'>
      <h2>{title}</h2>
      <table className='table-info'>
        <colgroup>
          <col className='table-info-col-key'/>
          <col className='table-info-col-value'/>
        </colgroup>
        <tbody>
          {rows.map((row, index) => {
            const isEmpty = row.value == null || row.value === '';

            return (
              <tr
                key={index}
                className={row.onClick && 'link'}
                onClick={row.onClick}
              >
                <td>{row.label}</td>
                <td>
                  <b>
                    {isEmpty ? '-' : row.value}
                    {!isEmpty && row.onClick && '⇒'}
                  </b>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
};

export default TableInfo;
