const enums = {};

enums.app = {
  live: {
    color: '#103595',
    label: 'Robo Live',
  },
  code: {
    color: '#f12535',
    label: 'Robo Code',
  },
  blockly: {
    color: '#ffa533',
    label: 'Robo Blockly',
  },
  universe: {
    color: '#750282',
    label: 'Robo Wunderkind',
  },
  unknown: {
    color: '#b5b5b5',
    label: 'Unknown',
  },
};

enums.fwUpdateResult = {
  successful: {
    color: '#29f44e',
    label: 'Successful',
  },
  failed: {
    color: '#fc3737',
    label: 'Failed',
  },
};

enums.helpType = {
  main_menu: {
    color: '#1dbce0',
    label: 'Main menu',
  },
  charging: {
    color: '#d9e01d',
    label: 'Charging',
  },
  firmware_update: {
    color: '#af3524',
    label: 'FW update',
  },
  project: {
    color: '#37e01d',
    label: 'Projects',
  },
};

enums.mainBlockConnected = {
  yes: {
    color: '#ffaf3c',
    label: 'Yes',
  },
  no: {
    color: '#878787',
    label: 'No',
  },
};

enums.modules = {
  // motors
  motor: {
    color: '#302aea',
    label: 'Smart Motor',
  },
  servo: {
    color: '#1cc5db',
    label: 'Servo Motor',
  },
  // LEDs
  rgb_light: {
    color: '#e2e202',
    label: 'RGB Light',
  },
  display_cube: {
    color: '#f4e538',
    label: 'LED Display',
  },
  depr_led_matrix: {
    color: '#c6c653',
    label: 'LED Matrix (disc.)',
  },
  // sensors
  button: {
    color: '#fc0000',
    label: 'Button',
  },
  light_sensor: {
    color: '#9e4747',
    label: 'Light Sensor',
  },
  distance_sensor: {
    color: '#ad1313',
    label: 'Distance Sensor',
  },
  motion_sensor: {
    color: '#960503',
    label: 'Motion Sensor',
  },
  linetracker: {
    color: '#681b1b',
    label: 'Line Follower',
  },
  accelerometer: {
    color: '#4f2323',
    label: 'Accelerometer',
  },
  // unused
  colour_sensor: {
    color: '#7e1296',
    label: 'Colour Sensor',
  },
  ir: {
    color: '#b945d3',
    label: 'Infrared',
  },
  camera: {
    color: '#aa7bb5',
    label: 'Camera',
  },
  meteo: {
    color: '#7b14aa',
    label: 'Meteo',
  },
};

enums.os = {
  android: {
    color: '#3add85',
    label: 'Android',
  },
  ios: {
    color: '#000000',
    label: 'iOS',
  },
  windows: {
    color: '#0079d7',
    label: 'Windows',
  },
  macos: {
    color: '#7c7b7c',
    label: 'Mac OS',
  },
  unknown: {
    color: '#b5b5b5',
    label: 'Unknown',
  },
};

enums.projectAction = {
  open: {
    color: '#adadad',
    label: 'Open',
  },
  build: {
    color: '#31a029',
    label: 'Build',
  },
  play: {
    color: '#3c51d8',
    label: 'Play',
  },
};

enums.projectType = {
  custom: {
    color: '#eada2a',
    label: 'Custom',
  },
  premade: {
    color: '#73cece',
    label: 'Premade',
  },
};

enums.projectUploadUsage = {
  used: {
    color: '#d3ce69',
    label: 'Used',
  },
  unused: {
    color: '#94d86a',
    label: 'Unused',
  },
};

enums.regType = {
  registered: {
    color: '#29f44e',
    label: 'Registered',
  },
  unregistered: {
    color: '#fc3737',
    label: 'Unregistered',
  },
};

enums.userProfileAgeGroup = {
  '<=5': {
    color: '#02eded',
    label: '5 and less',
  },
  '6-8': {
    color: '#02b6ed',
    label: '6 to 8',
  },
  '9-11': {
    color: '#026fed',
    label: '9 to 11',
  },
  '>=12': {
    color: '#0206ed',
    label: '12 and more',
  },
  none: {
    color: '#aaaaaa',
    label: 'None',
  },
};

enums.userProfileSource = {
  main_menu: {
    color: '#7e28e0',
    label: 'Profile menu',
  },
  parental_control: {
    color: '#598ce5',
    label: 'Parental controls',
  },
};

enums.userProfileStatus = {
  active: {
    color: '#26d331',
    label: 'Active',
  },
  deleted: {
    color: '#9e9e9e',
    label: 'Deleted',
  },
};

enums.userType = {
  personal: {
    color: '#cad31b',
    label: 'Personal',
  },
  teacher: {
    color: '#4736e2',
    label: 'Teacher',
  },
  student: {
    color: '#0ad4fc',
    label: 'Student',
  },
};

enums.yesNoNegative = {
  yes: {
    color: '#fc3737',
    label: 'Yes',
  },
  no: {
    color: '#29f44e',
    label: 'No',
  },
};

enums.yesNoPositive = {
  yes: {
    color: '#29f44e',
    label: 'Yes',
  },
  no: {
    color: '#fc3737',
    label: 'No',
  },
};

// Production analytics

enums.factory = {
  1: {
    color: '#0490f4',
    label: 'Yitoa',
  },
  2: {
    color: '#f4d804',
    label: 'Sunbloom',
  },
  255: {
    color: '#ff0000',
    label: 'Unknown'
  }
};

enums.module = {
  501: {
    color: '#302aea',
    label: 'Motor',
  },
  504: {
    color: '#ad1313',
    label: 'Ultrasonic',
  },
  506: {
    color: '#f4e538',
    label: 'LED Display',
  },
  507: {
    color: '#4f2323',
    label: 'Accelerometer',
  },
  601: {
    color: '#fc0000',
    label: 'Button',
  },
  602: {
    color: '#e2e202',
    label: 'RGB LED',
  },
  603: {
    color: '#681b1b',
    label: 'LineTracker',
  },
  604: {
    color: '#9e4747',
    label: 'Light',
  },
  605: {
    color: '#960503',
    label: 'PIR',
  },
};

enums.testResult = {
  passed: {
    color: '#29f44e',
    label: 'Passed',
  },
  failed: {
    color: '#fc3737',
    label: 'Failed',
  },
};

enums.devicesResult = {
  passed: {
    color: '#29f44e',
    label: 'Passed',
  },
  failed: {
    color: '#fc3737',
    label: 'Failed',
  },
};

// Marketing analytics

enums.osMarketing = {
  android: {
    color: '#3add85',
    label: 'Android',
  },
  ios: {
    color: '#000000',
    label: 'iOS',
  },
  windows: {
    color: '#0079d7',
    label: 'Windows',
  },
  macos: {
    color: '#7c7b7c',
    label: 'Mac OS',
  },
  unknown: {
    color: '#b5b5b5',
    label: 'Other',
  },
};

enums.clickConversion = {
  converted: {
    color: '#29f44e',
    label: 'Converted',
  },
  unconverted: {
    color: '#fc3737',
    label: 'Unconverted',
  },
};

export default enums;
