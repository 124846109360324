const ErrorIcon = () => {
  return (
    <div className='icon-main'>
      <svg
        width='100%'
        height='100%'
        viewBox='0 0 64 64'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g>
          <circle
            cx='32'
            cy='32'
            r='31'
            stroke='#F8B7B9'
            strokeWidth='2'
          />
          <path
            d='M18.0714 24.7619H21.5C22.2143 24.7619 22.6905 24.4286 22.7857 23.6667L24.0714 12.8572V4.52382C24.0714 3.76192 23.6429 3.33334 22.881 3.33334H16.6905C15.9286 3.33334 15.5 3.76192 15.5 4.52382V12.8572L16.7857 23.6667C16.881 24.4286 17.3571 24.7619 18.0714 24.7619ZM17.1667 36.6667H22.4048C23.1667 36.6667 23.5952 36.2381 23.5952 35.4762V30.2381C23.5952 29.4762 23.1667 29.0476 22.4048 29.0476H17.1667C16.4048 29.0476 15.9762 29.4762 15.9762 30.2381V35.4762C15.9762 36.2381 16.4048 36.6667 17.1667 36.6667Z'
            fill='#DD383F'
          />
        </g>
      </svg>
    </div>
  );
};

export default ErrorIcon;
