import 'react-datepicker/dist/react-datepicker.css';

import formatters from '../../../helpers/formatters';
import useApi from '../../../helpers/useApi';

import CustomBarChart from '../../../components/CustomBarChart';
import DateFilters from '../../../components/DateFilters';

const Timeline = () => {
  const { isLoading, data } = useApi('analytics/apps/project/timeline');

  return (
    <div className='stats-main-container'>
      <DateFilters />
      {isLoading ? (
        <div>Loading....</div>
      ) : (
        <>
          <div className='charts-container'>
            <CustomBarChart
              label={`Build time vs play time, total time ${formatters.secondsToDuration(data.userProjectActionDurationDaily.total)}`}
              data={data.userProjectActionDurationDaily.daily}
              enumName='projectAction'
              yAxisTickFormatter={formatters.secondsToDuration}
              tooltipValueFormatter={formatters.secondsToDuration}
            />
          </div>
        </>
      )}
    </div>
  )
};

export default Timeline;
